.p-pagination {
  display: flex;
  justify-content: center;
  &__item {
    background: $gray-2;
    width: 3.6rem;
    margin: 0 $spacer-sm;
    line-height: 3.6rem;
    text-align: center;
    border-radius: $border-rounded;
    @include link-color($gray-5);
    &:hover {
      background: $gray-3;
    }
    &.is-active {
      background: $primary;
      @include link-color($white);
    }
  }
}
