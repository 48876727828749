.p-faq {
  &__question {
    padding: $spacer $spacer-lg $spacer 0;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%228%22%20cy%3D%228%22%20r%3D%228%22%20fill%3D%22%2300C6CD%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M9.00003%204.36371C9.00003%203.81142%208.55232%203.36371%208.00003%203.36371C7.44775%203.36371%207.00003%203.81142%207.00003%204.36371V6.62608H4.63623C4.08395%206.62608%203.63623%207.07379%203.63623%207.62608C3.63623%208.17836%204.08395%208.62608%204.63623%208.62608H7.00003V11.6703C7.00003%2012.2225%207.44775%2012.6703%208.00003%2012.6703C8.55232%2012.6703%209.00003%2012.2225%209.00003%2011.6703V8.62608H11.3635C11.9158%208.62608%2012.3635%208.17836%2012.3635%207.62608C12.3635%207.07379%2011.9158%206.62608%2011.3635%206.62608H9.00003V4.36371Z%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E');
    background-position: right top $spacer*1.2;
    background-repeat: no-repeat;
    font-weight: $font-weight-normal;
    cursor: pointer;
    &:hover { font-weight: $font-weight-bold; }
    &.is-active {
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%228%22%20cy%3D%228%22%20r%3D%228%22%20fill%3D%22%2300C6CD%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M3.63623%207.62604C3.63623%207.07375%204.08395%206.62604%204.63623%206.62604H11.3635C11.9158%206.62604%2012.3635%207.07375%2012.3635%207.62604C12.3635%208.17832%2011.9158%208.62604%2011.3635%208.62604H4.63623C4.08395%208.62604%203.63623%208.17832%203.63623%207.62604Z%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E');
      font-weight: $font-weight-bold;
      text-decoration: none;
    }
  }
  &__answer {
    display: none;
    margin-bottom: $spacer;
    background: $gray-1;
    padding: $spacer;
    border-radius: $border-radius-sm;
    > * + * { margin-top: $spacer-sm; }
  }
  + .p-faq .p-faq__question {
    border-top: 1px dotted $border-color;
  }
}
