.p-scene {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-gap: $spacer-sm;
  grid-template-areas:
    "eyecatch"
    "title"
    "description";
  padding: $spacer;
  background: $white;
  border-radius: $border-radius;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, .05);
  img {
    grid-area: eyecatch;
    border-radius: $border-radius;
  }
  h3 {
    grid-area: title;
    font-size: $font-size-sub-heading;
  }
  p {
    grid-area: description;
    font-size: $font-size-caption;
  }
  @include up(lg) {
    grid-template-rows: auto 1fr;
    grid-template-columns: 16rem 1fr;
    grid-template-areas:
      "eyecatch title"
      "eyecatch description";
    h3, p { padding-left: $spacer-sm; }
    p { font-size: $font-size-body; }
  }
}
