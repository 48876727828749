.#{$glide-class} {
  $this: &;

  $se: $glide-element-separator;
  $sm: $glide-modifier-separator;

  &#{$se}arrow {
    position: absolute;
    top: 50%;
    height: 100%;
    background: #fff;
    z-index: 2;
    color: white;
    text-transform: uppercase;
    background-color: transparent;
    opacity: 1;
    cursor: pointer;
    transition: opacity 150ms ease, border 300ms ease-in-out;
    transform: translateY(-50%);
    line-height: 1;
    padding-right: $spacer;
    padding-left: $spacer;
    @include down(sm) { display: none; }

    &:focus { outline: none; }

    &.#{$sm}left {
      border-radius: $border-radius 0 0 $border-radius;
      left: 0;
    }

    &.#{$sm}right {
      right: 0;
      border-radius: 0 $border-radius $border-radius 0;
    }

    &#{$sm}disabled {
      opacity: 0.33;
    }
  }

  &#{$se}bullets {
    position: absolute;
    z-index: 2;
    bottom: $spacer;
    left: 50%;
    display: inline-flex;
    list-style: none;
    transform: translateX(-50%);
  }

  &#{$se}bullet {
    background-color: $gray-4;
    width: .7rem;
    height: .7rem;
    padding: 0;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    line-height: 0;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
    margin: 0 $spacer-sm;

    &:focus {
      outline: none;
    }

    &:hover,
    &:focus {
      background-color: $gray-2;
    }

    &#{$sm}active {
      background-color: $primary;
    }
  }

  &#{$sm}swipeable {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  &#{$sm}dragging {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}
