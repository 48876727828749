.l-footer {
  padding: $spacer-lg 0;
  background: $cyan-7;
  color: $white;
  font-size: $font-size-caption;
  @include down(sm) {
    text-align: center;
    padding: $spacer-lg 0;
  }
  .c-container {
    display: grid;
    grid-templat-rows: auto 1fr;
    grid-template-columns: 24rem 1fr;
    grid-gap: $spacer/2;
    grid-template-areas:
      "brand nav"
      "company company";
    @include down(md) {
      grid-template-columns: 20rem 1fr;
    }
    @include down(sm) { display: block; }
  }
  &__brand {
    grid-area: brand;
  }
  &__nav {
    grid-area: nav;
    @include down(sm) { display: none; }
    a {
      display: block;
      padding: $spacer-sm/2;
      @include link-color($white);
      &:hover { text-decoration: underline; }
      &::after {
        padding: $spacer-sm/2 $spacer-sm;
        content: $button-link-right-circle;
      }
    }
  }
  &__company {
    grid-area: company;
    margin-top: $spacer;
    address { margin-bottom: $spacer-sm; }
  }
}
