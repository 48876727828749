.p-sample-music {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-gap: $spacer-sm $spacer;
  margin-bottom: $spacer;
  grid-template-areas:
    "thumb title"
    "thumb list";
  &__thumb {
    grid-area: thumb;
    img {
      border-radius: $border-radius-sm;
      max-width: 15rem;
    }
  }
  &__title {
    grid-area: title;
    font-family: $font-family-serif;
    font-weight: $font-weight-thin;
    font-size: $font-size-heading;
  }
  &__list {
    grid-area: list;
    dl {
      display: flex;
      justify-content: space-between;
      font-size: $font-size-caption;
      + dl { margin-top: $spacer-sm; }
    }
  }
}
