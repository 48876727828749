.p-status {
  display: flex;
  justify-content: center;
  margin-bottom: $spacer-lg;
  &__item {
    text-align: center;
    font-size: $font-size-caption;
    margin: 0 $spacer-sm;
    &.is-active {
      color: $primary;
      font-weight: $font-weight-bold;
      .p-status__icon svg * { fill: $primary; }
    }
  }
  &__icon {
    margin-bottom: $spacer-sm/2;
  }
  &__icon, &__name {
    display: block;
  }
}
