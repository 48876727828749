.u-border-radius {
  border-radius: $border-radius;
  &-top {
    border-radius: $border-radius $border-radius 0 0;
  }
  &-bottom {
    border-radius: 0 0 $border-radius $border-radius;
  }
}
.u-border-color {
  @each $key, $value in $theme-colors {
    &-#{$key} {
      @include link-color(#{$value});
      border-color: $value !important;
    }
  }
  @each $key, $value in $colors {
    @for $i from 1 through 10 {
      &-#{$key}-#{$i} {
        @include link-color(#{$key}-#{$i});
        border-color: map-get($value, $i) !important;
      }
    }
  }
}
