.l-delivery {
  &__mv {
    background-image: url(../../images/help/delivery/bg-mv.png);
    background-size: cover;
    background-position: center;
    .c-container {
      padding-top: $spacer-lg;
      padding-bottom: $spacer-lg;
      background-image: url(../../images/help/delivery/eyecatch-mv.png);
      background-size: contain;
      background-position: bottom right;
      @include down(sm) {
        padding-top: $spacer;
        padding-bottom: 0;
        background-size: 80%;
        background-position: top right -170%;
      }
    }
    h1 {
      margin-top: $spacer-lg;
      margin-bottom: $spacer-lg;
      @include down(sm) {
        img { width: 64%; }
      }
    }
    .p-card {
      padding: $spacer-sm;
      margin-bottom: $spacer-lg;
      @include down(md) {
        background: rgba(255,255,255,0.6);
      }
      &__border {
        border: 1px solid $border-light-color;
        padding: $spacer;
        border-radius: $border-radius;
        text-align: center;
        .c-input { margin-bottom: $spacer-sm; }
        .c-form { margin-bottom: 0; }
        > * + * {
          margin-top: $spacer;
        }
      }
    }
  }
  &__result {
    display: none;
    margin-top: $spacer-lg;
    margin-bottom: $spacer-lg;
    text-align: center;
    dt, p { margin-bottom: $spacer-sm; }
    h3 {
      margin-bottom: $spacer;
      color: $pink-7;
    }
    strong { color: $cyan-8; }
  }
  &-point {
    position: relative;
    padding: $spacer-lg;
    margin-top: $spacer-lg * 1.5;
    margin-bottom: $spacer-lg;
    &__content {
      position: relative;
      z-index: 2;
    }
    &__icon {
      display: block;
      margin-top: -$spacer-lg * 1.8;
      margin-bottom: $spacer;
      @include down(sm) { text-align: center; }
    }
    &__heading {
      margin-top: $spacer;
      margin-bottom: $spacer * 1.5;
      color: $pink-8;
      font-weight: normal;
      font-family: $font-family-serif;
      font-size: $font-size-hero;
      @include down(sm) { text-align: center; }
    }
    p {
      margin-top: $spacer;
    }
    &__eyecatch {
      @include up(lg) {
        padding-left: $spacer;
      }
    }
    &__thumbnail {
      border-radius: $border-radius-sm;
      margin-bottom: $spacer;
      max-width: 100%;
      @include down(sm) {
        margin-top: $spacer;
      }
    }
    &__border {
      width: 60%;
      border: 1px solid $gray-5;
      position: absolute;
      background: $white;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      @include down(sm) {
        width: 100%;
      }
    }
    &.--reverse {
      .c-row {
        flex-direction: row-reverse;
      }
      .l-delivery-point__border {
        top: 0;
        bottom: 0;
        left: auto;
        right: 0;
      }
      .l-delivery-point__eyecatch {
        @include up(lg) {
          padding-right: $spacer;
        }
      }
    }
  }
  &__points {
    background: $gray-1;
    padding: $spacer-lg 0 $spacer;
    margin-bottom: $spacer-lg;
  }
  &__calendar {
    margin-bottom: $spacer-lg;
    section {
      text-align: center;
      h3 { margin-bottom: $spacer-sm; }
      p {
        text-align: left;
        margin-bottom: $spacer-lg;
      }
    }
    @include up(lg) {
      margin: $spacer-lg;
      padding-left: $spacer-lg;
      padding-right: $spacer-lg;
      section {
        padding-left: $spacer;
        padding-right: $spacer;
      }
    }
  }
}
