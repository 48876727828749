.l-draft {
  &__mv {
    margin-bottom: $spacer-lg;
    .c-container {
      background-image: url(../../images/features/draft/eyecatch-mv.jpg);
      background-position: right top;
      background-size: contain;
      padding-top: $spacer-lg;
      padding-bottom: $spacer-lg;
    }
    h1 {
      width: 70%;
      margin-bottom: $spacer;
    }
    @include down(sm) {
      margin-bottom: $spacer;
      .c-container {
        background-position: right -6rem top;
      }
      h1 {
        width: 80%;
      }
      p {
        display: inline-block;
        padding: $spacer-sm;
        background: $white;
        img { width: 15rem; }
      }
    }
  }
  &__points {
    margin-bottom: $spacer;
    section {
      margin-top: 2rem;
      margin-bottom: $spacer;
      padding: 0 $spacer $spacer;
      border-radius: $border-radius;
      position: relative;
      text-align: center;
      background: $gray-1;
    }
    small {
      position: relative;
      top: -2rem;
    }
    h2 {
      margin-top: -$spacer-sm;
      font-family: $font-family-serif;
      font-weight: normal;
      font-size: $font-size-heading;
      border-bottom: 1px dotted $cyan-8;
      padding-bottom: $spacer;
      margin-bottom: $spacer;
    }
    @include down(md) {
      br { display: none; }
      p { text-align: left; }
    }
  }
  &__mypage {
    text-align: center;
    margin-bottom: $spacer-lg;
    @include down(sm) {
      text-align: left;
      margin-bottom: $spacer;
    }
  }
  &__flow {
    text-align: center;
    padding: 0 $spacer;
    section {
      margin: $spacer-lg 0;
      padding: 0 $spacer-sm;
      h3 {
        margin: $spacer-sm 0 $spacer;
        font-weight: normal;
        font-family: $font-family-serif;
        font-size: $font-size-sub-heading;
      }
      p {
        text-align: left;
        margin-top: $spacer;
        font-size: $font-size-caption;
      }
    }
    @include down(md) { padding: 0; }
    @include down(sm) {
      text-align: left;
      section {
        text-align: center;
        p { text-align: left; }
      }
    }
  }
  &__faq {
    padding: $spacer-lg 0;
    background-size: auto auto;
    dl {
      dt {
        margin-bottom: $spacer;
        padding-left: 2.8rem;
        background-image: url(../../images/features/draft/icon-faq-question.svg);
        background-size: 1.8rem;
        background-position: top .1rem left;
        font-size: $font-size-sub-heading;
      }
      dd {
        margin-bottom: $spacer;
        padding-left: 2.8rem;
        background-image: url(../../images/features/draft/icon-faq-answer.svg);
        background-size: 1.5rem;
        background-position: top .1rem left;
        p {
          margin-top: $spacer-sm;
        }
      }
    }
    hr {
      background: none;
      background-image: url(../../images/features/draft/hr-faq.svg);
      background-repeat: repeat-x;
      background-position: bottom;
      background-size: 100% auto;
      height: $spacer;
      margin-bottom: $spacer;
    }
  }
  &__posting {
    margin-bottom: $spacer;
    @include down(sm) {
      padding: 0 $spacer-lg;
    }
  }
  &__voices {
    padding: $spacer-lg 0;
    background-color: $cyan-1;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 5px, rgba(208, 245, 247, 1) 5px, rgba(208, 245, 247, 1) 7px );
    background-repeat: repeat;
    background-position: center;
  }
}
