#payjp_checkout_box input[type=button] {
  background: $white !important;
  border: 1px solid $gray-7 !important;
  color: $gray-7 !important;
  -webkit-appearance: none !important;
  border-radius: 0.4rem !important;
  display: inline-flex !important;
  padding: 0.9rem 1.4rem !important;
  font-size: 1.4rem !important;
  line-height: 1.5;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  cursor: pointer !important;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all .3s;
  &:hover {
    background: $gray-7 !important;
    border-color: $gray-7 !important;
    color: $white !important;
  }
}