.l-about {
  &-mv {
    background: linear-gradient(90deg, #4EB8EF 0%, #17C7CE 38.12%, #C3BEFA 100%);
    font-family: $font-family-serif;
    color: $white;
    position: relative;
    padding-top: $spacer;
    margin-bottom: $spacer;
    width: 100%;
    overflow: hidden;
    h1 {
      margin-top: $spacer-lg * 2;
      margin-bottom: $spacer;
    }
    p {
      font-size: $font-size-sub-heading;
    }
    .c-container {
      z-index: 2;
    }
    &__eyecatch {
      border-radius: 50%;
      border: 1px solid $white;
      padding: .2rem;
      float: right;
      display: inline-block;
    }
    &__layer {
      &-left {
        position: absolute;
        top: -10rem;
        left: -10rem;
        width: 35%;
        transform: rotate(170deg);
      }
      &-right {
        position: absolute;
        top: -10rem;
        right: -10rem;
        width: 40%;
        transform: rotate(-20deg);
      }
    }
    &__footer {
      vertical-align: bottom;
      position: absolute;
      bottom: -1px;
      width: 101%;
    }
    @include down(md) {
      padding-bottom: $spacer-lg * 2;
      h1 { margin-top: $spacer; width: 65%; }
      &__eyecatch {
        width: 25%;
      }
    }
    @include down(sm) {
      padding-bottom: $spacer-lg * 1.5;
      h1 { margin-top: $spacer; width: 65%; }
      p { font-size: $font-size-caption; }
      &__eyecatch {
        position: absolute;
        top: 0;
        right: -5%;
        width: 35%;
      }
    }
  }
  .p-heading.--lg {
    margin-bottom: $spacer-sm;
    strong {
      font-size: $font-size-hero * 1.2;
    }
  }
  &-reasons {
    @include up(md) {
      background-image: url(../../images/help/about/bg-reasons.svg);
      background-size: contain;
      padding: $spacer-lg 0;
    }
  }
  &-reason {
    position: relative;
    padding: $spacer-lg;
    margin-top: $spacer-lg * 2;
    margin-bottom: $spacer-lg;
    &__content {
      position: relative;
      z-index: 2;
    }
    &__icon {
      display: block;
      margin-top: -$spacer-lg * 2;
      margin-bottom: $spacer;
      img { background-color: $white; }
    }
    &__heading {
      margin-bottom: $spacer-lg;
    }
    p {
      margin-top: $spacer;
    }
    &__eyecatch {
      @include up(lg) {
        padding-left: $spacer;
      }
    }
    &__thumbnail {
      border-radius: $border-radius-sm;
      margin-bottom: $spacer;
      max-width: 100%;
      @include down(sm) {
        margin-top: $spacer;
      }
    }
    &__border {
      width: 60%;
      border: 1px solid $primary;
      position: absolute;
      background: $white;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      @include down(sm) {
        width: 100%;
      }
    }
    &.--reverse {
      .c-row {
        flex-direction: row-reverse;
      }
      .l-about-reason__border {
        top: 0;
        bottom: 0;
        left: auto;
        right: 0;
      }
      .l-about-reason__eyecatch {
        @include up(lg) {
          padding-right: $spacer;
        }
      }
    }
  }
  &-message {
    .c-container {
      max-width: 700px;
      @include down(sm) {
        padding-right: 0;
      }
    }
    p {
      margin-bottom: $spacer;
      font-family: $font-family-serif;
      font-size: $font-size-sub-heading;
      @include down(sm) {
        br { display: none; }
      }
    }
    &__comment {
      padding-top: $spacer-lg;
      padding-bottom: $spacer-lg;
      @include up(md) {
        padding-top: $spacer-lg * 2;
        padding-bottom: $spacer-lg * 2;
      }
    }
    &__signiture {
      margin-top: $spacer-lg;
      color: $primary;
      @include down(sm) {
        display: none;
      }
      img {
        display: inline-block;
        margin-bottom: $spacer-sm;
        vertical-align: middle;
        margin-right: $spacer-sm;
      }
    }
    &__eyecatch {
      background-image: url(../../images/help/about/eyecatch-staff.png);
      background-size: cover;
    }
  }
}
