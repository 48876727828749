.l-article {
  line-height: 1.8;
  font-feature-settings: $font-feature-settings;
  letter-spacing: $letter-spacing;
  h1 {
    font-size: $font-size-hero * 1.2;
    margin-bottom: $spacer * 1.4;
    line-height: 1.4;
    @include down(sm) { font-size: $font-size-hero; }
  }
  h2 {
    margin: $spacer*1.5 0 $spacer;
    line-height: 1.4;
  }
  h3 {
    margin-bottom: $spacer;
    line-height: 1.4;
  }
  h4 {
    margin-bottom: $spacer-sm;
    line-height: 1.4;
  }
  h5 {
    margin-bottom: $spacer-sm;
    line-height: 1.4;
  }
  p, ul, ol, dl, table {
    margin-bottom: $spacer;
  }
  > img {
    margin-bottom: $spacer;
  }
  &.--center {
    text-align: center;
  }
}
