.l-drawer {
  position: fixed;
  top: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  color: #fff;
  transition: opacity .4s, visibility 0s ease 1s;
  opacity: 0;
  visibility: hidden;
  &.is-active {
    transition-delay: 0s;
    opacity: 1;
    visibility: visible;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    animation: drawer .5s cubic-bezier(0,.35,.30,.80) 0s 1 both;
    @keyframes drawer {
      0% { clip-path: circle(10% at 100% 0); }
      100% { clip-path: circle(100% at 50% 50%); }
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    padding: $spacer;
    align-items: center;
    background: rgba($cyan-6,0.98);
  }
  &__content {
    flex-grow: 1;
    height: 100%;
    display: flex;
    align-items: center;
    background: rgba($cyan-6,0.98);
  }
  &__shortcut {
    @include grid($spacer/2,12)
    margin-bottom: $spacer-lg;
    .c-col {
      grid-column: 6 span;
      padding: 0;
      &.--sm {
        grid-column: 4 span;
      }
    }
  }
  &__nav {
    margin-bottom: $spacer-lg;
    a {
      display: block;
      padding: $spacer*.6 $spacer $spacer*.6 $spacer*.6;
      @include link-color($white);
      background: $button-link-right-circle;
      background-position: right $spacer-sm center;
      background-repeat: no-repeat;
      &:hover { text-decoration: underline; }
    }
  }
  &__sns {
    text-align: center;
    a {
      display: inline-block;
      padding: $spacer-sm;
    }
  }
  .p-inquiry {
    text-align: center;
    margin-bottom: $spacer;
    .p-inquiry__tel a { @include link-color($white); }
  }
}
