.p-nav {
  display: flex;
  &__item {
    padding: $spacer/2 $spacer;
    &.is-active { color: $primary; }
  }
  &.--center { justify-content: center; }
  &.--right { justify-content: end; }
  &.--sm {
    font-size: $font-size-caption;
    .p-nav__item { padding: $spacer/4 $spacer; }
  }
  &.--column {
    flex-direction: column;
  }
  &.--pills {
    .p-nav__item {
      border-radius: $border-rounded;
      &.is-active {
        color: $white;
        background: $primary;
      }
    }
  }
  &.--posts {
    flex-direction: column;
    .p-nav__item {
      padding: 0;
      margin-bottom: $spacer-sm;
      display: block;
      @include link-colors($gray-8,$primary,$gray-8,$gray-8);
      @include down(md) {
        margin-bottom: 0;
        padding: $spacer*.75 0;
        @include link-color($black);
        background: none;
        background-image: $button-nav;
        background-repeat: no-repeat;
        background-position: right center;
        + .p-nav__item { border-top: 1px dotted $border-color; }
      }
    }
  }
}
.p-nav-wrapper {
  margin-top: -$spacer-sm;
  @include up(lg) { margin-top: -$spacer; }
}
