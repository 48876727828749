.u-list {
  > li {
    margin-bottom: $spacer-sm;
  }
  &-caution {
    padding-left: $spacer;
    position: relative;
    &::before {
      position: absolute;
      top: -0.15rem;
      margin-left: -$spacer;
      content: '※';
      padding-right: $spacer-sm * 0.8;
    }
  }
  &-none {
    padding-left: 0;
    list-style-type: none;
  }
}
.u-list {
  &-cautions {
    padding-left: 0;
    list-style-type: none;
    > li {
      padding-left: $spacer;
      position: relative;
      &::before {
        position: absolute;
        top: -0.15rem;
        margin-left: -$spacer;
        content: '※';
        padding-right: $spacer-sm * 0.8;
      }
    }
  }
}
