%heading {
  text-align: center;
  margin-bottom: $spacer;
  @include up(lg) { margin-bottom: $spacer-lg; }
  font-weight: $font-weight-bold;
  font-size: $font-size-heading;
}
%heading_small {
  display: block;
  font-family: $font-family-curve;
  font-size: 2rem;
  line-height: 1;
  font-weight: 200;
  color: $secondary;
}
%heading-lg {
  font-family: $font-family-serif;
  font-weight: normal;
  font-size: $font-size-hero * 1.2;
  strong {
    font-weight: normal;
    color: $primary;
  }
  small {
    font-family: $font-family-serif;
    display: inline;
    color: $body;
    font-size: $font-size-hero * 1.1;
  }
  @include down(sm) {
    font-size: $font-size-hero;
    small { font-size: $font-size-hero * .9; }
  }
}
%heading-sm {
  font-weight: $font-weight-bold;
  font-size: $font-size-sub-heading;
  border-bottom: 1px solid $border-color;
  padding: $spacer 0;
  line-height: 1;
  width: 100%;
  text-align: left;
  @include down(sm) {
    font-size: $font-size-body;
    margin-bottom: $spacer;
    background: $gray-6;
    color: $white;
    padding: $spacer-sm $spacer;
    border-bottom: none;
  }
}
%heading-xs {
  font-weight: $font-weight-bold;
  font-size: $font-size-sub-heading;
  line-height: 1;
  width: 100%;
  text-align: left;
}
%heading-sidebar {
  font-size: $font-size-sub-heading;
  margin-bottom: $spacer;
  width: 100%;
  @include down(sm) {
    font-size: $font-size-body;
    background: $gray-6;
    color: $white;
    padding: $spacer-sm $spacer;
  }
}
%heading-sm-link {
  float: right;
  line-height: 1;
  font-weight: $font-weight-normal;
  &:link { text-decoration: underline; }
  @include down(sm) {
    color: $white;
    @include link-color($white);
  }
}
%heading-sm-container {
  @include down(sm) {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
    width: 100vw;
  }
}
.p-heading {
  text-align: center;
  margin-bottom: $spacer;
  h1,h2,h3,h4 {
    @extend %heading;
    margin-bottom: $spacer-sm;
  }
  small {
    @extend %heading_small;
  }
  &.--lg {
    h1,h2,h3,h4 { @extend %heading-lg; }
  }
  &.--md {
    font-size: $font-size-body;
  }
  &.--sm {
    text-align: left;
    margin-bottom: $spacer-sm;
    @include up(lg) { margin-bottom: $spacer; }
    h1,h2,h3,h4 { @extend %heading-sm; }
    &__link { @extend %heading-sm-link; }
  }
  &.--xs {
    text-align: left;
    h1,h2,h3,h4 { @extend %heading-xs; }
    &__link { @extend %heading-sm-link; }
  }
  &.--sidebar {
    text-align: left;
    @extend %heading-sidebar;
  }
  &.--center {
    text-align: center !important;
  }
  &.--cm {
    color: $primary;
    display: flex;
    justify-content: center;
    margin-bottom: $spacer-sm;
    h1,h2,h3,h4 {
      display: inline-block;
      padding-left: $spacer-lg;
      padding-right: $spacer-lg;
      background-image: url(../../images/bg-concentrated-left.svg), url(../../images/bg-concentrated-right.svg);
      background-position: left center, right center;
      img {
        margin-bottom: .3rem;
        margin-right: $spacer-sm;
      }
    }
  }
}
h1,h2,h3,h4 {
  &.p-heading {
    @extend %heading;
    &.--sm {
      @extend %heading-sm;
      &__link { @extend %heading-sm-link; }
    }
    &.--xs {
      @extend %heading-xs;
      &__link { @extend %heading-sm-link; }
    }
  }
}
.c-container {
  .p-heading.--sm, .p-heading.--sidebar {
    @extend %heading-sm-container;
  }
}
