// Base
%button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: $border-width solid $gray-7;
  background: $gray-7;
  color: $white;
  font-size: $button-font-size;
  text-decoration: none;
  white-space: nowrap;
  line-height: $line-height;
  border-radius: $border-radius-sm;
  padding: $button-padding-y $button-padding-x;
  transition: all .3s;
  @include link-color($white);
  &:hover {
    cursor: pointer;
    background: darken($gray-7,5%);
    border-color: darken($gray-7,5%);
  }
}

// Style
.c-button {
  @extend %button;
  &.--block {
    width: 100%;
  }
  &.--rounded {
    border-radius: $border-rounded !important;
    padding-right: $spacer-lg;
    padding-left: $spacer-lg;
  }
  &.--outline {
    background: $white;
    border-color: $gray-7;
    color: $gray-7;
    &:hover {
      background: $gray-7;
      border-color: $gray-7;
      color: $white;
    }
  }
  &.--transparent {
    background: none;
    border-color: $white;
    color: $white;
  }
  &.--bold {
    font-weight: $font-weight-bold;
  }
}

// Themes
@each $key, $value in $button-colors {
  .c-button {
    &.--#{$key} {
      background: $value;
      border-color: $value;
      color: $white;
      .c-icon path { fill: $value; }
      &:hover {
        background: darken($value,7%);
        border-color: darken($value,7%);
        .c-icon path { fill: darken($value,7%); }
      }
    }
    &.--outline-#{$key} {
      background: $white;
      border-color: $value;
      color: $value;
      .c-icon path { fill: $value; }
      &:hover {
        background: $value;
        border-color: $value;
        color: $white;
        .c-icon path { fill: $white; }
      }
    }
    &.--transparent-#{$key} {
      background: none;
      border-color: $value;
      color: $value;
      .c-icon path { fill: $value; }
    }
    &[disabled].--#{$key} {
      background-color: $gray-4;
      border-color: $gray-4;
      color: $white;
    }
  }
}

// Size
.c-button {
  @each $key, $value in $button-sizes {
    &.--#{$key} {
      padding: map-get($value, padding-y) map-get($value, padding-x);
      font-size: map-get($value, font-size);
      border-radius: map-get($value, border-radius);
      @if $key == lg {
        font-weight: $font-weight-bold;
      }
    }
  }
}

// Link
.c-button {
  &.--link {
    background: none;
    border: 1px solid transparent;
    text-decoration: underline;
    @extend %base-link-color;
  }
  &.--link-primary {
    background: none;
    border: 1px solid transparent;
    text-decoration: underline;
    @extend %base-link-color;
    color: $cyan-7 !important;
    &:hover {
      cursor: pointer;
      color: darken($gray-7,5%) !important;
    }
  }
  &.--link-right, &.--link-left, &.--nav {
    justify-content: start;
    background: none;
    border: 1px solid transparent;
    border-radius: 0;
    background-repeat: repeat-x, no-repeat;
    @extend %base-link-color;
  }
  &.--link-right {
    background-image: $button-link-border,$button-link-right;
    padding-left: 0 !important;
    padding-right: $button-arrow-width + $button-arrow-padding  !important;
    background-size: 4px auto, $button-arrow-width auto;
    background-position: center bottom, right center;
    @include button-arrow-size(right);
  }
  &.--link-left {
    background-image: $button-link-border,$button-link-left;
    padding-left: $button-arrow-width + $button-arrow-padding + .5rem;
    padding-right: 0 !important;
    background-size: 4px auto, $button-arrow-width auto;
    background-position: center bottom, left center;
    @include button-arrow-size(left);
  }
  &.--nav {
    border: 1px solid transparent;
    border-radius: 0 !important;
    border-bottom: 1px dotted $border-color;
    background: none;
    background-image: $button-nav;
    background-repeat: no-repeat;
    background-size: $button-nav-width auto;
    background-position: right $button-nav-padding center;
    padding-left: $button-nav-padding;
    padding-right: $button-nav-width + $button-nav-padding;
    @include button-nav-size();
  }
  &.--no-border {
    border: none!important;
  }
  &.--movie {
    line-height: 1.2;
    img { margin-right: $spacer/3; }
    span {
      font-size: 1.28rem;
    }
    strong {
      display: block;
      font-size: 1.7rem;
    }
  }
  &.--cart {
    padding-top: $spacer * .9;
    padding-bottom: $spacer * .9;
  }
  &.--line {
    @extend .u-border-color-teal-7;
    @extend .u-bg-teal-7;
  }
  &.--heartbeat {
    animation: heartbeat 4s infinite;
    @keyframes heartbeat {
      0% {
        transform: scale(1.03);
      }
      2% {
        transform: scale(1);
      }
      94% {
        transform: scale(1);
      }
      96% {
        transform: scale(1.03);
      }
      98% {
        transform: scale(1);
      }
      100% {
        -webkit-transform: scale(1.03);
      }
    }
    &:hover, &[disabled] {
      animation: none;
    }
  }
  &.--shake {
    animation: shake 4s infinite;
    @keyframes shake {
      0% {
        transform: translate(0px, 2px);
      }
      2.5% {
        transform: translate(0px, -2px);
      }
      5% {
        transform: translate(0px, 2px);
      }
      7.5% {
        transform: translate(0px, -2px);
      }
      10% {
        transform: translate(0px, 2px);
      }
      12.5% {
        transform: translate(0px, -2px);
      }
      15% {
          transform: translate(0px, 0px);
      }
    }
    &:hover, &[disabled] {
      animation: none;
    }
  }
  &.--reflect {
    position: relative;
    overflow: hidden;
    border: none;
    padding-top: $spacer * .97;
    padding-bottom: $spacer * .97;
    &:before {
      position: absolute;
      content: '';
      display: inline-block;
      top: -180px;
      left: 0;
      width: 30px;
      height: 100%;
      background-color: #fff;
      animation: reflect 4s ease-in-out infinite;
      @keyframes reflect {
        0% {
          transform: scale(0) rotate(45deg); opacity: 0;
        }
        80% {
          transform: scale(0) rotate(45deg); opacity: 0.5;
        }
        81% {
          transform: scale(4) rotate(45deg); opacity: 1;
        }
        100% {
          transform: scale(50) rotate(45deg); opacity: 0;
        }
      }
    }
    &:hover, &[disabled] {
      &:before {
        animation: none;
      }
    }
  }
}
