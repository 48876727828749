.c-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: - $grid-gutter;
  margin-left: - $grid-gutter;
  @each $media-query in $media-queries {
    @for $i from 0 through 5 {
      &.--gap-#{$media-query}-#{$i} {
        @include up(#{$media-query}) {
          margin-right: - $grid-gutter * $i;
          margin-left: - $grid-gutter * $i;
          .c-col {
            padding-right: $grid-gutter * $i;
            padding-left: $grid-gutter * $i;
          }
        }
      }
    }
  }
  @each $media-query in $media-queries {
    &.--#{$media-query}-row {
      @include up(#{$media-query}) { flex-direction: row; }
    }
    &.--#{$media-query}-column {
      @include up(#{$media-query}) { flex-direction: column; }
    }
    &.--#{$media-query}-row-reverse {
      @include up(#{$media-query}) { flex-direction: row-reverse; }
    }
    &.--#{$media-query}-column-reverse {
      @include up(#{$media-query}) { flex-direction: column-reverse; }
    }
  }
  @each $i in center,flex-start,flex-end {
    &.--#{$i} {
      justify-content: #{$i};
    }
    @each $media-query in $media-queries {
      &.--#{$media-query}-#{$i} {
        @include up(#{$media-query}) {
          justify-content: #{$i};
        }
      }
    }
  }
}
.c-col {
  box-sizing: border-box;
  flex-basis: 50%;
  padding-right: $grid-gutter;
  padding-left: $grid-gutter;
  @each $media-query in $media-queries {
    @for $i from 1 through $grid-columns {
      &.--#{$media-query}-#{$i} {
        @include up(#{$media-query}) {
          flex-basis: 100% / $grid-columns * $i;
        }
      }
    }
  }
}
