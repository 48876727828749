.u-hr {
  background: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: $border-color;
  &.--dotted {
    border-top-style: dotted;
  }
  &.--white {
    border-color: $white;
  }
}
