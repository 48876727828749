.p-voice {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-gap: $spacer-sm;
  grid-template-areas:
    "thumb attribute"
    "comment comment";
  padding: $spacer;
  background: $white;
  border-radius: $border-radius;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, .05);
  &__thumb {
    align-self: center;
    grid-area: thumb;
    img {
      border-radius: $border-radius-sm;
      width: 6.5rem;
    }
  }
  &__title {
    font-weight: $font-weight-bold;
    margin-bottom: $spacer-sm*.7;
  }
  &__attribute {
    grid-area: attribute;
    align-self: center;
    font-size: $font-size-caption;
    color: $gray-8;
    margin-left: $spacer-sm;
    h3,h4,h5 {
      font-weight: $font-weight-bold;
      font-size: $font-size-body;
      color: $body;
      small { font-size: $font-size-caption; }
    }
    table {
      th, td {
        font-size: $font-size-small;
        vertical-align: top;
        a {
          text-decoration: underline;
          margin-right: $spacer-sm/2;
        }
      }
      th {
        width: 5.5rem;
      }
    }
    dl {
      display: flex;
      flex-wrap: wrap;
      dt {
        margin-right: $spacer/3;
        font-weight: $font-weight-normal;
      }
    }
  }
  &__comment {
    grid-area: comment;
    line-height: 1.8;
  }
  &__note {
    display: inline;
    padding-bottom: $spacer-sm;
    line-height: 2.4;
    font-feature-settings: $font-feature-settings;
    letter-spacing: $letter-spacing;
    background: url(../../images/bg-line-dotted.png);
    background-repeat: repeat-x;
    background-position: bottom;
    strong {
      @extend .u-marker;
      padding: 0 .2rem;
    }
  }
  &.--product {
    .p-voice__thumb img {
      width: 5rem;
    }
  }
  &.--excerpt {
    .p-voice__comment {
      height: 12rem;
      overflow: hidden;
      position: relative;
      &.is-active {
        height: auto;
        .p-voice__more {
          display: none;
        }
      }
    }
    .p-voice__more {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      font-size: $font-size-caption;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 20%, #fff 60%);
      a {
        display: block;
        padding: 3rem $spacer-sm 0;
        text-decoration: underline;
      }
    }
  }
  &.--lg {
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "thumb attribute"
      "comment comment";
    grid-gap: $spacer;
    margin-bottom: $spacer-lg;
    .p-voice__thumb {
      align-self: flex-start;
      img {
        width: 9rem;
        @include up(lg) {
          width: 8rem;
        }
        border-radius: $border-radius-sm;
      }
    }
    .p-voice__comment {
      table {
        margin-top: $spacer;
        tr {
          th, td {
            padding: $spacer-sm/3;
            font-size: $font-size-caption;
            a { margin-right: $spacer-sm; }
          }
          th {
            padding-right: $spacer-sm;
          }
        }
      }
    }
    .p-voice__attribute {
      color: $black;
      h2 {
        font-size: $font-size-title * .85;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-bold;
        margin-bottom: $spacer-sm;
        @include up(md) {
          font-size: $font-size-title;
        }
      }
      h3,h4,h5 {
        font-size: $font-size-sub-heading;
        margin-bottom: $spacer-sm;
        small { font-size: $font-size-caption; }
      }
      dl { margin-top: $spacer-sm/2; }
      dt { font-weight: $font-weight-bold; }
      a {
        text-decoration: underline;
        margin-right: $spacer-sm;
      }
      table {
        th, td {
          font-size: $font-size-caption;
        }
      }
    }
  }
}
.p-voice-radius {
  $voice-radius: 8rem;
  background: $white;
  border-radius: $voice-radius;
  display: grid;
  position: relative;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "thumb heading"
    "thumb content";
  + .p-voice-radius {
    margin-top: $spacer-lg;
  }
  &__thumb {
    grid-area: thumb;
    width: $voice-radius * 2;
    padding: .3rem;
    border-radius: $voice-radius;
  }
  &__heading {
    grid-area: heading;
    font-family: $font-family-serif;
    font-size: $font-size-hero;
    font-weight: normal;
    margin-bottom: $spacer-sm;
    padding: $spacer $spacer-lg 0 $spacer;
  }
  &__content {
    grid-area: content;
    padding: 0 $spacer-lg $spacer $spacer;
  }
  &__name {
    font-size: $font-size-sub-heading;
    font-weight: bold;
    text-align: right;
  }
  @include up(lg) {
    &:after {
      content: url(../../images/features/modification/icon-balloon.svg);
      position: absolute;
      bottom: -$spacer-sm;
      right: 0;
    }
    &__heading {
      font-size: $font-size-hero * 1.2;
    }
    &__name {
      margin-right: $spacer-lg;
    }
  }
  @include down(md) {
    border-radius: $border-radius;
    &__thumb {
      border-radius: $border-radius;
      padding: $spacer 0 $spacer $spacer;
    }
  }
  @include down(sm) {
    padding: $spacer;
    grid-template-areas:
      "thumb heading"
      "content content";
    &__thumb {
      padding: 0;
      margin: 0 auto $spacer;
      width: 7.5rem;
    }
    &__heading {
      padding: $spacer-sm $spacer;
    }
    &__content {
      padding: 0;
    }
  }
}
