.c-alert {
  padding: $alert-padding-y $alert-padding-x;
  font-size: $alert-font-size;
  border-radius: $alert-border-radius;
  margin-bottom: $spacer;
  &.--error {
    background: $alert-error-bg;
    color: $alert-error-color;
    border: 1px solid $alert-error-border;
  }
  &.--warning {
    background: $alert-warning-bg;
    color: $alert-warning-color;
    border: 1px solid $alert-warning-border;
  }
  &.--success {
    background: $alert-success-bg;
    color: $alert-success-color;
    border: 1px solid $alert-success-border;
  }
  &.--info {
    background: $alert-info-bg;
    color: $alert-info-color;
    border: 1px solid $alert-info-border;
    .c-select {
      background-color: $white;
      border-color: $white;
    }
  }
  &__heading {
    font-size: $font-size-body;
    font-weight: $font-weight-bold;
    margin-bottom: $spacer-sm;
  }
}
