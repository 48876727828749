.l-editor {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  &__description {
    padding: $spacer-lg 0;
    text-align: center;
    @extend .l-article;
    @include down(sm) {
      text-align: left;
    }
  }
  &__form {
  }
  &.--colored {
    background: $gray-1;
  }
  &__my-order {
    display: none;
    &.is-active {
      display: block;
    }
  }
}
