.p-counter {
  display: grid;
  margin-bottom: $spacer;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto;
  grid-gap: $spacer-sm;
  grid-template-areas:
    "name pricing"
    "subtitle subtitle"
    "description description"
    "cart cart"
    "action action";
  &__name {
    grid-area: name;
    align-self: center;
    font-family: $font-family-serif;
    font-weight: $font-weight-thin;
    font-size: $font-size-hero * 1.2;
    &.--sm {
      font-size: $font-size-hero;
    }
  }
  &__subtitle {
    grid-area: subtitle;
    font-weight: bold;
    font-size: $font-size-body;
  }
  &__description {
    grid-area: description;
    @include down(md) { margin-bottom: $spacer-sm; }
  }
  &__pricing {
    grid-area: pricing;
    align-self: center;
    .u-font-pricing {
      font-size: $font-size-hero * 1.5;
    }
    @include down(sm) {
      .u-font-pricing {
        font-size: $font-size-hero * 1.3;
      }
      small {
        font-feature-settings: "palt" 1;
      }
      line-height: 1;
      text-align: right;
      margin-bottom: $spacer-sm * .5;
    }
    &__list {
      text-decoration: line-through;
      font-size: $font-size-caption;
    }
    &__lead {
      font-size: $font-size-caption;
      font-weight: 700;
      margin-right: $spacer-sm;
      color: $pink-9;
      @include down(sm) {
        font-size: 1rem;
      }
    }

  }
  &__cart {
    grid-area: cart;
    text-align: center;
    margin-top: $spacer-sm;
  }
  &__action {
    grid-area: action;
    display: flex;
    justify-content: center;
  }
  @include up(lg) {
    grid-template-rows: auto;
    grid-template-columns: auto;
    grid-gap: $spacer-sm;
    grid-template-areas:
      "name"
      "subtitle"
      "description"
      "pricing"
      "cart"
      "action";
    &__name {
      font-size: $font-size-hero * 1.6;
      &.--sm {
        font-size: $font-size-hero * 1.3;
      }
    }
  }
  &.is-fixed {
    @include up(lg) {
      width: 360px;
      position: fixed;
      animation: topToBottom .5s;
      top: $spacer;
      @keyframes topToBottom {
        0% {
         transform: translateY(-3rem);
         opacity: 0;
        }
        100%{
         transform: translateY(0);
         opacity: 1;
        }
      }
    }
  }
  &-footer {
    display: none;
    &.is-active {
      @include down(md) {
        display: flex;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        background: $white;
        box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.03);
        padding: 20px;
        margin-bottom: 0;
        animation: bottomToTop .5s;
        border-top: 1px solid $border-light-color;
        @keyframes bottomToTop {
          0% {
           transform: translateY(10rem);
          }
          100%{
           transform: translateY(0);
          }
        }
      }
    }
    .p-counter {
      &__cart {
        margin-right: 6rem;
        margin-top: 0;
        width: 100%;
        text-align: left;
      }

    }
  }
  &-sns {
    display: flex;
    align-items: center;
    justify-content: center;
    &__heading {
      margin-right: $spacer;
    }
    &__links {

    }
  }
}
