.p-feature {
  display: flex;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  grid-gap: 0;
  grid-template-areas:
    "heading heading"
    "description eyecatch";
  &__heading {
    grid-area: heading;
    font-size: $font-size-heading;
    margin-bottom: $spacer-sm;
    @include down(sm) {
      font-size: $font-size-heading * .95;
    }
  }
  &__description {
    grid-area: description;
    font-feature-settings: $font-feature-settings;
    letter-spacing: $letter-spacing;
    line-height: $line-height * 1.2;
    > * + * { margin-top: $spacer-sm; }
    @include up(lg) {
      p {
        display: inline;
      }
    }
  }
  &__eyecatch {
    grid-area: eyecatch;
    align-self: start;
    padding-left: $spacer-sm;
    max-width: 13rem;
    @include up(lg) {
      max-width: 14rem;
      padding-left: $spacer;
    }
    &.--sm {
      @include up(lg) {
        max-width: 10rem;
      }
    }
  }
  &.--float {
    display: block;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
    .p-feature__eyecatch {
      float: right;
      @include down(md) {
        padding-bottom: $spacer-sm;
      }
    }
  }
  + .p-feature {
    margin-top: $spacer;
    padding-top: $spacer;
    border-top: 1px dotted $border-color;
  }
  @include down(sm) {
    h3 {
      font-size: $font-size-body;
      text-align: center;
    }
  }
}
