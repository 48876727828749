@mixin table {
  > thead > tr, > tbody > tr, > tfoot > tr, > tr {
    @content;
  }
}

// Base
%table {
  width: 100%;
  @include table() {
    > th, > td {
      padding: $spacer*.75;
      border-top: 1px dotted $table-border-color;
      vertical-align: top;
      > * + * { margin-top: $spacer-sm; }
    }
    > th { font-weight: $font-weight-normal; }
    &:first-child {
      > th, > td {
        border-top: none;
      }
    }
  }
}

// Size
.c-table {
  @extend %table;
  &.--sm {
    @include table() {
      > th, > td {
        padding: $spacer*.5;
        font-size: $font-size-caption;
      }
    }
  }
  &.--auto { width: auto; }
}

// Style
.c-table {
  &.--bold {
    @include table() {
      > th { font-weight: $font-weight-bold; }
    }
  }
  &.--color {
    @include table() {
      &:first-child th { border-top-left-radius: $border-radius; }
      &:last-child th { border-bottom-left-radius: $border-radius; }
      > th { background: $gray-1; }
    }
  }
  &.--no-border {
    @include table() {
      > th, > td {
        padding: $spacer*.5;
        border: none;
      }
    }
    &.--sm {
      @include table() {
        > th, > td {
          padding: $spacer*.25;
        }
      }
    }
  }
  &.--border {
    border-collapse: separate;
    border: 1px solid $table-border-color;
    border-radius: $border-radius;
    background: $white;
    @include table() {
      > th, > td {
        border-top: 1px solid $table-border-color;
        border-left: 1px solid $table-border-color;
        &:first-child {
          border-left: none;
        }
      }
      &:first-child { th, td { border-top: none; } }
    }
  }
  &.--right {
    @include table() {
      > th, > td {
        &:last-child { text-align: right; }
      }
    }
  }
}

// Responsive
.c-table {
  @each $media-query in $media-queries {
    @include up(#{$media-query}) {
      &.--#{$media-query}-vertical {
        @include table() {
          display: flex;
          flex-direction: column;
          > th, > td { min-width: 100% !important; }
        }
        &.--border {
          @include table() {
            > td { border-left: none; }
            &:first-child {
              > th, > td {
                &:first-child {
                  border-radius: $border-radius $border-radius 0 0;
                  border-bottom: 1px solid $table-border-color;
                }
              }
            }
          }
        }
      }
    }
    @include up(#{$media-query}) {
      &.--#{$media-query}-horizontal {
        @include table() {
          display: table-row;
          > th, > td { min-width: auto !important; }
        }
        &.--border {
          @include table() {
            > td { border-left: 1px solid $table-border-color; }
            &:first-child {
              > th, > td {
                &:first-child {
                  border-radius: $border-radius 0 0 0;
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
