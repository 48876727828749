.u-d {
  @each $display in $displays {
    &-#{$display} {
      display: #{$display} !important;
    }
  }
  @each $media-query in $media-queries {
    @include up(#{$media-query}) {
      @each $display in $displays {
        &-#{$media-query}-#{$display} {
          display: #{$display} !important;
        }
      }
    }
  }
}
