.u-color {
  @each $key, $value in $theme-colors {
    &-#{$key} {
      @include link-color(#{$value});
      color: $value !important;
    }
  }
  @each $key, $value in $colors {
    @for $i from 1 through 10 {
      &-#{$key}-#{$i} {
        @include link-color(#{$key}-#{$i});
        color: map-get($value, $i) !important;
      }
    }
  }
}
