// Base
html {
  font-size: 62.5%;
}
body {
  min-height: 100vh;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  font-size: $font-size-body;
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-normal;
  line-height: $line-height;
  &.is-removed-footer {
    @include down(md) {
      .l-footer { display: none; }
    }
  }
  &.is-fixed {
    position: fixed;
    height: auto;
    left: 0;
  }
}
* {
  outline: none;
  font-style: normal;
}

// Disable Scrollbar
::-webkit-scrollbar {
  display: none;
}

// Border
hr {
  border: none;
  height: $border-width;
  background-color: $border-color;
}
// Link
%base-link-color {
  @each $key, $link-color in $link-colors {
    &:#{$key} {
      color: $link-color;
    }
  }
}
a {
  text-decoration: $link-decoration;
  @extend %base-link-color;
}

// Image
img {
  vertical-align: bottom;
  max-width: 100%;
}

// Heading
h1 {
  font-size: $font-size-hero;
  font-weight: $font-weight-bold;
}
h2 {
  font-size: $font-size-title;
  font-weight: $font-weight-bold;
}
h3 {
  font-size: $font-size-heading;
  font-weight: $font-weight-bold;
}
h4 {
  font-size: $font-size-sub-heading;
  font-weight: $font-weight-bold;
}
h5, h6 {
  font-size: $font-size-body;
  font-weight: $font-weight-bold;
}

// List
ul, ol {
  padding-left: $spacer * 1.6;
}
dl dt {
  font-weight: $font-weight-bold;
}

// Table
table {
  border-collapse: collapse;
  border-spacing: 0;
  th {
    text-align: left;
  }
}

// Fieldset
fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}
body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

// Legend
legend {
  max-width: initial;
}

