@each $size in width, height {
  @for $i from 0 through 40 {
    .u-#{$size}-#{$i} {
      width: $spacer * $i !important;
    }
  }
  .u-#{$size}-100p {
    width: 100% !important;
  }
}
@each $media-query in $media-queries {
  @include up(#{$media-query}) {
    @each $size in width, height {
      @for $i from 0 through 40 {
        .u-#{$size}-#{$media-query}-#{$i} {
          width: $spacer * $i !important;
        }
      }
      .u-#{$size}-#{$media-query}-100p {
        width: 100% !important;
      }
    }
  }
}
