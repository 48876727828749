.l-nanairoweek {
  &__mv {
    background-color: #b8eef7;
    // background-image: url(../../images/sales/nanairoweek/bg-pattern.svg);
    background-size: contain;
    background-repeat: repeat; 
    background-position: center;
    padding: $spacer-sm 0 0;
    text-align: center;
    margin-bottom: $spacer-lg;
  }
  &__summery {
    text-align: center;
    @include down(sm) {
      br { display: none; }
    }
  }
  &__campaigns {
    margin-bottom: $spacer;
    .p-card {
      border: 1px solid $primary;
      background: $cyan-1;
      margin-bottom: $spacer;
      &.--orange {
        border: 1px solid $orange-5;
        background: $orange-1;
        .p-point-number {
          color: $secondary;
          border-color: $secondary;
        }
      }
      h3,h4 {
        text-align: center;
        margin-bottom: $spacer;
      }
      .p-point-number {
        font-size: $font-size-hero;
      }
      .p-card__body{
        padding: $spacer;
      }
    }
  }
}
