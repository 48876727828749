.p-project-select {
  text-align: center;
  &__label {
    margin-bottom: $spacer-sm;
  }
  &__select {
    .c-select {
      @extend .--auto;
      @include down(sm) {
        width: 100%;
      }
    }
  }
}
