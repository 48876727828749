@mixin checkbox {
  display: inline-flex;
  align-items: center;
  margin-right: $spacer;
  input[type="checkbox"],input[type="radio"] {
    &:not(:checked),&:checked {
      position: absolute;
      left: -9999px;
      + label {
        position: relative;
        padding-left: $spacer * 2;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: $checkbox-size;
          height: $checkbox-size;
          border: 1px solid $checkbox-bg-color;
          background: $checkbox-bg-color;
        }
        &::after {
          position: absolute;
          transition: all .1s;
        }
      }
      &:focus + label::before {
        border-color: lighten($checkbox-bg-color,2%);
        background: lighten($checkbox-bg-color,2%);
      }
    }
    &:not(:checked) {
      + label:after {
        opacity: 0;
        transform: scale(0);
      }
    }
    &:checked + label {
      &::before {
        background: $checkbox-checked-bg-color;
        border-color: $checkbox-checked-bg-color;
      }
      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }
    &:disabled {
      &:not(:checked),&:checked {
        + label::before {
          border-color: $gray-1;
          background-color: $gray-1;
        }
      }
      &:checked {
        + label {
          color: $gray-6;
          &::after {
           color: $gray-6;
          }
        }
      }
    }
  }
  &.--block {
    display: flex;
    margin-bottom: $spacer-sm;
  }
}
