.u-bg {
  @each $key, $value in $colors {
    @for $i from 1 through 10 {
      &-#{$key}-#{$i} {
        background: map-get($value, $i) !important;
      }
    }
  }
  &-dotted {
    background-image: url(../../images/bg-dotted.svg);
    background-repeat: repeat;
    background-position: center;
    background-size: 0.5rem;
  }
  &-border {
    position: relative;
    &-waved {
      background-image: url(../../images/bg-wave.svg);
      background-repeat: repeat-x;
      position: absolute;
      width: 100%;
      top: -$spacer*.8;
      height: $spacer-lg;
    }
  }
}
