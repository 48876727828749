input[class*='formbox-field_'] {
  font-size: $font-size-body !important;
  font-family: $font-family-sans-serif !important;
  font-weight: $font-weight-normal !important;
  line-height: $line-height !important;
  -webkit-appearance: none !important;
  display: inline-flex !important;
  border: 1px solid $input-bg-color !important;
  border-radius: $border-radius-sm !important;
  padding: $input-padding-y $input-padding-x !important;
  font-size: $font-size-body !important;
  line-height: $line-height !important;
  background: $input-bg-color !important;
  width: 100%;
  &:focus {
    border: 1px solid $input-focus-border-color !important;
    background: $input-focus-bg-color !important;
    transition: all 0.2s;
  }
}
div[id*='btnSubmit_'] {
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: $button-font-size !important;
  text-decoration: none !important;
  white-space: nowrap !important;
  line-height: $line-height !important;
  border-radius: $border-radius-sm !important;
  padding: $button-padding-y $button-padding-x !important;
  transition: all 0.3s;
}
