.u-text {
  &-underline { text-decoration: underline !important; }
  @each $text-alignment in $text-alignments {
    &-#{$text-alignment} { text-align: #{$text-alignment} !important; }
  }
  @each $media-query in $media-queries {
    @include up(#{$media-query}) {
      @each $text-alignment in $text-alignments {
        &-#{$media-query}-#{$text-alignment} {
          text-align: #{$text-alignment} !important;
        }
      }
    }
  }
}
