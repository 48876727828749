.p-cart {
  margin-bottom: $spacer;
  @include up(lg) { margin-bottom: $spacer-lg; }
  &__item {
    display: flex;
    border-bottom: 1px dotted $border-color;
  }
  &__thumb {
    padding: $spacer $spacer-sm;
    border-radius: $border-radius;
    width: 15rem;
    img { border-radius: $border-radius-sm; }
  }
  &__attribute {
    padding: $spacer $spacer-sm;
    dt {
      font-family: $font-family-serif;
      font-weight: $font-weight-thin;
      font-size: $font-size-sub-heading;
      small {
        font-family: $font-family-sans-serif;
        padding-left: $spacer-sm;
        font-size: $font-size-caption;
        @include down(sm) {
          display: none;
        }
      }
    }
    dd {
      font-size: $font-size-small;
      .u-font-pricing {
        font-weight: $font-weight-thin;
        font-size: $font-size-body;
      }
    }
  }
  &__delete {
    align-self: center;
    margin-left: auto;
    padding: $spacer $spacer-sm;
    color: $muted;
    text-align: right;
    text-decoration: underline;
    font-size: $font-size-caption;
  }
}
