@mixin button-arrow-size($direction: right) {
  @each $key, $value in $button-arrow-sizes {
    &.--#{$key} {
      background-size: 4px auto, map-get($value, button-arrow-width) auto;
      @if $key == lg {
        padding-#{$direction}: map-get($value, button-arrow-width) + map-get($value, button-arrow-padding) !important;
        background-position: center bottom, $direction .5rem center;
      } @else {
        padding-#{$direction}: map-get($value, button-arrow-width) + map-get($value, button-arrow-padding);
      }
    }
  }
}
@mixin button-nav-size() {
  @each $key, $value in $button-nav-sizes {
    &.--#{$key} {
      background-size: map-get($value, button-nav-width) auto;
      padding-right: map-get($value, button-nav-width) + map-get($value, button-nav-padding);
      padding-left: map-get($value, button-nav-padding);
    }
  }
}
@mixin link-color($color) {
  @each $attr in link, visited, active  {
    &:#{$attr} {
      color: $color;
    }
  }
}
@mixin link-colors($link: $black, $hover: $black, $active: $black, $visited: $black) {
  &:link { color: $link; }
  &:visited { color: $visited; }
  &:active { color: $active; }
  &:hover { color: $hover; }
}
