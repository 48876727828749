.p-jumbotron {
  padding: $spacer 0;
  margin-bottom: $spacer;
  &.--lg {
    padding: $spacer-lg 0;
    @each $media-query in $media-queries {
      @include up(#{$media-query}) {
        &.--#{$media-query}-lg {
          padding: $spacer-lg 0;
        }
      }
    }
  }
  &.--center {
    text-align: center;
  }
  .p-heading {
    margin-bottom: 0;
  }
  // セット割キャンペーン
  &.--set {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../images/sales/set/bg-mv-set.svg);
    min-height: 215px;
    background-size: cover;
    text-align: center;
    margin-bottom: $spacer-lg;
    @include down(sm) { margin-bottom: $spacer; }
  }
}
