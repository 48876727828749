.c-container {
  margin: 0 auto;
  padding: 0 $spacer;
  width: 100%;
  position: relative;
  overflow: hidden;
  @include up(md) { padding: 0 $spacer-lg; }
  @include up(lg) { max-width: $max-container-width; }
  &.--lg { max-width: $max-container-width * 1.2; }
  &.--sm { max-width: $max-container-width * .8; }
  &.--xs { max-width: $max-container-width * .5; }
  &.--visible { overflow: visible; }
}
