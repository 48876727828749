.p-step {
  @extend .p-card;
  @extend .p-card__body;
  @extend .--shadow;
  display: flex;
  align-items: center;
  margin-top: $spacer;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, .05);
  &.is-completed {
    .c-label { @extend .--success; }
  }
  &.is-working {
    .c-label { @extend .--warning; }
  }
  &__content {
    margin-right: $spacer;
    font-size: $font-size-caption;
    @include up(md) { font-size: $font-size-body; }
    h2, h3, h4 {
      font-size: $font-size-body;
      margin-bottom: $spacer-sm/2;
      @include up(md) { font-size: $font-size-sub-heading; }
    }
    .c-label { margin-right: $spacer-sm; }
  }
  &__action {
    margin-left: auto;
  }
  &.--kit {
    @include down(sm) {
      text-align: center;
      flex-direction: column;
      .p-step__action {
        margin-top: $spacer;
      }
    }
  }
}
