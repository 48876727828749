.p-coupon {

  &__code {
    display: flex;
    justify-content: center;
  }
  dl {
    display: inline-block;
    padding: $spacer $spacer-lg*2;
    background: $cyan-1;
    border-radius: $border-radius;
    text-align: center;
    @include down(sm) {
      display: block;
      width: 100%;
    }
    dt {
      font-weight: bold;
      font-size: $font-size-caption;
    }
    dd {
      font-weight: bold;
      font-size: $font-size-hero;
      color: $primary;
    }
  }
  &__copy {
    display: flex;
    justify-content: center;
    margin-top: $spacer;
  }
}
