.p-mv {
  width: 100%;
  background: url(../../images/bg-mv-feather.png), linear-gradient(220deg, rgba($cyan-2, 0.15) 10%, $cyan-2 50%);
  background-position: center;
  background-size: cover;
  &__container {
    @extend .c-container;
    height: 45vh;
    min-height: 32rem;
    max-height: 60rem;
    display: flex;
    flex-direction: column;
    position: relative;
    background: url(../../images/bg-mv-bride.webp);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right 1rem top 1rem;
    transition: all .2s;
  }
  &__title {
    display: flex;
    align-items: center;
    height: 100%;
  }
  &__heading {
    img {
      width: 40rem;
      @include down(md) {

      }
      @include down(sm) {
        width: 18rem;
      }
    }
    // color: $cyan-10;
    // font-size: $font-size-hero *.9;
    // font-family: $font-family-serif;
    // font-weight: $font-weight-thin;
    // letter-spacing: .1em;
    // margin-top: $spacer;
    // strong {
    //   font-weight: $font-weight-thin;
    //   letter-spacing: .05em;
    //   font-size: $font-size-hero *.85;
    // }
  }
  &__actions {
  }
  &__badge {
    margin-bottom: $spacer;
    @include down(sm) {
      width: 9rem;
    }
  }
  &__shortcut {
    background: rgba($white,.6);
    border-radius: $border-radius-sm $border-radius-sm 0 0;
    padding: $spacer/2;
    @include grid($spacer/2,12);
    .c-col {
      grid-column: 6 span;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__cm {
    width: 22rem;
    position: absolute;
    right: 0;
    bottom: $spacer-lg;
    background: $primary;
    padding: $spacer-sm;
    text-align: center;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    .p-youtube {
      border: none;
    }
    &__heading {
      margin-bottom: $spacer-sm;
      padding: 0 $spacer;
    }
    @include down(md) {
      display: none;
    }
  }
  @include up(md) {
    &__container {
      justify-content: center;
      background-position: right 2rem top 1rem;
      height: 55rem;
      min-height: 55rem;
      max-height: 55rem;
    }
    &__heading {
      font-size: $font-size-hero * 1.2;
      strong {
        font-weight: $font-weight-thin;
        font-size: $font-size-hero * 1.1;
        letter-spacing: .05em;
      }
    }
    &__actions {
      display: flex;
      margin-top: 0;
      margin-bottom: $spacer * 2;
    }
    &__badge {
      margin-bottom: 0;
      margin-right: $spacer;
    }
    &__shortcut {
      border-radius: $border-radius-sm;
    }
  }
  @include up(lg) {
    &__container {
      background-size: 460px auto;
      background-position: right top 1rem;
    }
    &__heading {
      font-size: $font-size-hero * 1.6;
      strong {
        font-size: $font-size-hero * 1.5;
        font-weight: $font-weight-thin;
        letter-spacing: .05em;
      }
    }
    &__shortcut {
      background: none;
      border-radius: 0;
      padding: 0;
    }
  }
}
.no-webp .p-mv {
  &__container {
    background: url(../../images/bg-mv-bride.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right -100px bottom;
  }
}
