.p-support-type {
  text-align: center;
  border: 2px solid #A5EDF0;
  position: relative;
  margin-top: $spacer-lg;
  padding: 0 $spacer-lg $spacer-lg;
  &__question {
    position: relative;
    top: -1.7rem;
    background: $cyan-7;
    color: $white;
    border-radius: $spacer;
    padding: .5rem $spacer-lg;
    display: inline-block;
    font-weight: bold;
  }
  &__answer {
    margin-bottom: $spacer;
    h3 { margin-bottom: $spacer; }
  }
  &__thumb {
    position: absolute;
    width: 32rem;
    top: 0;
    left: -18rem;
    &.--right {
      left: auto;
      right: -18rem;
    }
  }
  .p-voice-radius__content {
    font-size: $font-size-caption;
  }
  .p-voice-radius__name {
    margin-top: $spacer;
    font-size: $font-size-caption;
  }
  @include down(md) {
    &__answer {
      br { display: none; }
    }
    &__thumb {
      display: none;
    }
  }
  @include down(sm) {
    padding: 0 $spacer $spacer;
    &__question {
      font-size: $font-size-caption;
    }
  }

}
