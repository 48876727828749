$marker-diameter: 8px;
$marker-indent: $spacer-sm;

.p-timeline {
  position: relative;
  padding: 0 0 $spacer-lg $spacer-lg;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    top: $marker-indent;
    bottom: -$marker-indent;
    left: $marker-diameter/2;
    background: $primary;
  }
  &:not(:first-of-type):not(:last-of-type) .tl_marker {
    background: transparent;
  }
  &:last-of-type:before {
    content: none;
  }
  &__label {
    @extend .c-label;
    @extend .--primary;
    padding-left: $spacer-sm;
    padding-right: $spacer-sm;
    margin-bottom: $spacer-sm;
    margin-left: -$spacer-sm;
    margin-top: $spacer-sm/3;
  }
  &__heading {
    font-weight: bold;
    font-size: $font-size-sub-heading;
    margin-bottom: $spacer-sm;
  }
  &__body {
    * + * {
      margin-top: $spacer-sm;
    }
  }
  &__marker {
    content: '';
    display: block;
    position: absolute;
    top: $marker-indent;
    left: 0;
    width: $marker-diameter;
    height: $marker-diameter;
    border-radius: $border-rounded;
    border: solid 1px $primary;
    background: $primary;
  }
}
