.l-my {
  background: $cyan-1;
  padding: $spacer-lg 0;
  flex-grow: 2;
  h1 {
    font-size: $font-size-heading;
    color: $cyan-10;
  }
  &__article {
    @extend .l-article;
    text-align: center;
    //margin-bottom: $spacer-lg;
  }
  &__date {
    font-weight: $font-weight-thin;
    font-size: $font-size-hero;
    font-family: $font-family-serif;
    font-style: italic;
    color: $primary;
    line-height: 1;
  }
  &__description {
    padding: 0 0 $spacer-lg;
    text-align: center;
    @extend .l-article;
  }
  &__project-wrapper {
    display: none;
    &.is-active {
      display: block;
    }
  }
}

.l-my-footer {
  @extend .c-container;
  display: flex;
  justify-content: center;
  @include down(md) {
    flex-direction: column;
    .c-button.--nav:last-child {
      border-bottom: 0;
    }
  }
  .c-button.--nav {
    @include up(lg) {
      border-bottom: 0;
    }
  }
  .js-add-order-links {
    display: none;
    &.is-active {
      display: block;
    }
  }
}
