.l-support {
  &__mv {
    position: relative;
    background: #dbe1e1;
    margin-bottom: $spacer-lg;
    @include down(sm) { margin-bottom: $spacer; }
    .c-container {
      background-image: url(../../images/features/support/eyecatch-mv.png);
      background-position: top 20% left;
      background-size: 80%;
      text-align: right;
      padding: $spacer-lg 0;
      h1 { margin-bottom: $spacer; }
      @include down(md) {
        background-size: cover;
      }
      @include down(sm) {
        background-position: center left;
        padding-top: $spacer-lg * 1.5;
        h1 {
          padding-right: $spacer;
          img { width: 15rem; }
          span img { width: 6rem;  }
        }
        p {
          margin-top: $spacer;
          text-align: center;
        }
      }
    }
  }
  &__mv-dotted {
    background: $white url(../../images/features/support/bg-dotted.svg);
    width: 50%;
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
  }
  &__menu {
    text-align: center;
    margin-bottom: $spacer-lg;
    @include down(sm) { margin-bottom: $spacer; }
    h2, p {
      margin-bottom: $spacer;
    }
  }
  &__type {
    padding: $spacer 0;
    @include down(sm) { padding: 0 0 $spacer; }
    background-size: auto auto;
    background-color: $cyan-1;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 5px, rgba(208, 245, 247, 1) 5px, rgba(208, 245, 247, 1) 7px );
  }
  &__refund {
    text-align: center;
    padding: $spacer-lg 0;
    h2 {
      padding: 0 $spacer-lg;
      margin-bottom: $spacer-lg;
    }
    p {
      line-height: 1.5;
      margin-top: $spacer;
      strong {
        font-size: $font-size-sub-heading;
        color: $cyan-8;
      }
    }
    @include down(sm) {
      text-align: left;
      h2 { margin-bottom: $spacer; }
      p {
        padding-left: $spacer-lg;
        padding-right: $spacer-lg;
      }
      br { display: none; }
    }
  }
  &__comment {
    background: url(../../images/features/support/eyecatch-comment.jpg);
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;
    padding: $spacer-lg 0;
    h2 {
      margin-bottom: $spacer-lg;
    }
    p {
      margin-bottom: $spacer;
      font-family: $font-family-serif;
      background: rgba(255,255,255,.4);
      display: inline-block;
      padding: .2rem;
    }
    dl {
      text-align: right;
      dt,dd {
        font-family: $font-family-serif;
        font-size: $font-size-sub-heading;
        font-weight: normal;
      }
    }
    &__eyecatch {
      display: none;
    }
    @include down(md) {
      padding: $spacer 0 0;
      background-size: contain;
      background-position: top right -7.5rem;
      h2 {
        margin: $spacer-sm * 10 0;
        width: rem;
      }
      p {
        margin-bottom: 0;
        padding: $spacer;
        display: block;
        width: 100%;
        br { display: none; }
      }
    }
    @include down(sm) {
      &__message {
        background: rgba(255,255,255,0.7);
        padding: $spacer-lg $spacer-lg 0;
      }
      h2 {
        width: 24rem;
      }
      p {
        padding: 0;
        margin-bottom: $spacer;
        background: none;
        font-size: $font-size-body * .95;
        line-height: 1.8;
        br { display: none; }
      }
    }
  }
}
