.p-product {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto;
  grid-gap: $spacer-sm;
  grid-template-areas:
    "thumb thumb"
    "name attribute"
    "description description";
  background: $white;
  border: 1px solid $border-light-color;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  position: relative;
  &:hover {
    color: $body;
  }
  &__thumb {
    grid-area: thumb;
    display: grid;
    img {
      //border: 1px solid $gray-2;
      grid-area: 1 / 1 / 1 / 1;
      border-radius: $border-radius $border-radius 0 0;
    }
  }
  &__name {
    grid-area: name;
    align-self: center;
    font-family: $font-family-serif;
    font-weight: $font-weight-thin;
    font-size: $font-size-title * 1.1;
    h1,h2,h3 { font-size: $font-size-caption; }
    padding-left: $spacer;
    &.--sm {
      font-size: $font-size-title;
    }
  }
  &__subtitle {
    margin-bottom: $spacer-sm;
    font-weight: $font-weight-bold;
    font-size: $font-size-small;
    @include down(sm) {
      font-size: $font-size-body;
    }
  }
  &__attribute {
    grid-area: attribute;
    align-self: center;
    padding-right: $spacer;
    .c-label {
      vertical-align: bottom;
      padding: $label-padding-y*2 $label-padding-x;
    }
  }
  &__pricing {
    font-size: $font-size-small;
    line-height: 1.1;
    text-align: center;
    span {
      color: $primary;
      font-family: $font-family-serif;
      font-weight: $font-weight-thin;
      font-size: $font-size-title;
      font-style: italic;
      padding-left: .2rem;
      padding-right: .2rem;
    }
    small {
      font-size: $font-size-small * .9;
      font-feature-settings: "palt" 1;
    }
  }
  &__description {
    padding-left: $spacer;
    padding-right: $spacer;
    margin-bottom: $spacer;
    grid-area: description;
  }
  &__link {
    background: $white;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
  }
  &__movie,
  &__movie iframe {
    border-radius: 0.8rem 0.8rem 0 0;
  }
  &__movie {
    grid-area: 1 / 1 / 1 / 1;
    opacity: 0;
    background-color: #fff;
    transition: opacity 0.5s;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .player {
    max-width: none;
    height: auto;
    .vp-video-wrapper .vp-preview {
      background-position: initial;
    }
  }
}
