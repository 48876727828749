@mixin input {
  -webkit-appearance: none;
  display: inline-flex;
  border: 1px solid $input-bg-color;
  border-radius: $border-radius-sm;
  padding: $input-padding-y $input-padding-x;
  font-size: $font-size-body;
  line-height: $line-height;
  background: $input-bg-color;
  width: 100%;
  &:focus {
    border: 1px solid $input-focus-border-color;
    background: $input-focus-bg-color;
    transition: all .2s;
  }
  &.--auto {
    width: auto;
  }
}
