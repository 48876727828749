.p-post {
  display: flex;
  margin-bottom: $spacer;
  + .p-post {
    border-top: 1px dotted $border-color;
    padding-top: $spacer;
  }
  &__thumbnail {
    max-width: 7rem;
    margin-right: $spacer;
    flex-shrink: 0;
    img {
      border-radius: $border-radius;
    }
  }
  &__content {
    h2,
    h3,
    h4 {
      font-size: $font-size-sub-heading;
      font-weight: $font-weight-bold;
      margin-bottom: $spacer-sm;
    }
  }
  &__attribute {
    font-size: $font-size-caption;
    margin-bottom: $spacer/2;
  }
  &__excerpt {
    color: $muted;
    font-size: $font-size-caption;
  }
  &.--sm {
    border-bottom: none;
    padding: 0;
    margin-bottom: $spacer-sm;
    align-items: center;
    .p-post__thumbnail {
      max-width: 4.5rem;
      margin-right: $spacer-sm * 1.2;
      img {
        border-radius: $border-radius-sm;
      }
    }
    + .p-post {
      border-top: 1px dotted $border-color;
      padding-top: $spacer-sm;
    }
  }
  &__date {
    display: inline-flex;
    margin-bottom: $spacer-sm;
    font-size: $font-size-caption;
    margin-right: $spacer-sm;
    &.--published {
      &:before {
        content: '公開日';
        margin-right: $spacer-sm/2;
      }
    }
    &.--modified {
      &:before {
        content: '更新日';
        margin-right: $spacer-sm/2;
      }
    }
  }
}
