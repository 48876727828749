.p-invoice {
  background: $orange-1;
  padding: $spacer-lg;
  border-radius: $border-radius;
  @extend .u-mb-2;
  h2,h3,h4 {
    font-size: $font-size-sub-heading;
    color: $cyan-10;
    @extend .u-mb-1;
  }
  table {
    @extend .c-table;
    @extend .--no-border;
    @extend .--right;
    @extend .u-mb-lg-1;
  }
  &__action {
    text-align: center;
    margin-top: $spacer;
  }
}
