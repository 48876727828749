.p-video {
  background: #000;
  border-radius: $border-radius;
  max-width: 72rem;
  margin: 0 auto;
  margin-bottom: $spacer-lg;
  &__header {
    text-align: center;
    color: $white;
    padding: $spacer-sm;
  }
  &__content {
    video, source, .c-video {
      border-radius: 0 0 $border-radius $border-radius;
      width: 100%;
    }
  }
}
