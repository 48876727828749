.l-smakon {
  border: 4px solid $smakon;
  min-height: 100vh;
  text-align: center;
  &__mv {
    text-align: center;
    padding: 0 $spacer;
    h1 {
      margin-bottom: $spacer-lg;
      margin-top: $spacer-lg;
      img {
        width: 220px;
        @include down(sm) {
          width: 180px;
        }
      }
    }
    p {
      margin-bottom: $spacer-lg;
      img {
        width: 300px;
      }
    }
  }
  &__pricing {
    background: $smakon;
    color: $white;
    display: flex;
    justify-content: center;
    margin-bottom: $spacer-lg;
    padding: $spacer 0;
    img {
      padding: $spacer;
      width: 220px;
    }
  }
  &__copyright {
    font-size: $font-size-small;
    color: $gray-6;
    margin-bottom: $spacer-lg;
  }
  .p-point__number {
    color: $smakon;
    border-color: $smakon;
  }
  .p-heading small {
    color: $smakon;
  }
  .p-nav.--pills .p-nav__item {
    @include link-colors($black,$smakon,$black,$black);
    &.is-active {
      background: $smakon;
      @include link-colors($white,$white,$white,$white);
    }
  }
  .p-coupon__code dl {
    background: $blue-1;
    dd {
      color: $smakon;
    }
  }
}
