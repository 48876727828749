.p-accordion {
  &-trigger {
    cursor: pointer;
  }
  &-content {
    transition: opacity .4s, visibility 0s ease 1s;
    opacity: 0;
    display: none;
    &.is-active {
      transition-delay: 0s;
      opacity: 1;
      display: block;
      animation-duration: .5s;
      animation-name: accodion;
      @keyframes accodion {
        0% {
          display: none;
          opacity: 0;
          transform: translateY(-1rem);
        }
        1% {
          display: block;
          opacity: .2;
          transform: translateY(-0.5rem);
        }
        100% {
          display: block;
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}

