.p-banners {
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  @include up(md) {
    padding-left: $spacer;
    padding-right: $spacer;
  }
  @include down(sm) {
    .p-slider.--sm-1 .p-slider__item {
      flex-basis: 65%;
    }
  }
}
