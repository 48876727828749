.p-youtube {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  width: 100%;
  display :block;
  padding: 56.25% 0 0 0;
  position: relative;
  background-size: cover;
  border: 3px solid $cyan-6;
  img {
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    border: 2px solid $primary;
  }
}
