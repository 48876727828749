.l-navbar {
  width: 100%;
  overflow: hidden;
  header {
    background: $primary;
    color: $white;
    display: flex;
    align-items: center;
    padding: $spacer 0;
    svg { vertical-align: middle; }
  }
  &__back {
    padding-left: $spacer-sm;
    padding-right: $spacer-sm;
  }
  &__title {
    font-size: $font-size-sub-heading;
    font-weight: $font-weight-bold;
  }
  &__nav {
    width: 100%;
    background: $cyan-7;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $cyan-7;
    color: $white;
    max-width: 100%;
    a {
      @include link-color($white);
      display: inline-flex;
      justify-content: center;
      padding: $spacer-sm * 1.5;
      width: 100%;
      position: relative;
      &.is-active {
        background: $cyan-8;
        &.is-filled:after {
          color: $cyan-8;
        }
      }
      &.is-filled {
        &:after {
          content: "入力完了";
          @extend %label;
          background: $white;
          padding: $label-padding-y $label-padding-x * 1.5;
          border: none;
          color: $cyan-7;
          font-weight: 700;
          margin-left: .4rem;
          @include down(sm) {
            content: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2211%22%20height%3D%228%22%20viewBox%3D%220%200%2011%208%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10%201L3.8125%207L1%204.27273%22%20stroke%3D%22%23FFE86C%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
            position: absolute;
            top: .2rem;
            right: .2rem;
            margin-left: 0;
            margin-top: 0;
            padding: 0;
            background: none;
          }
        }
      }
    }
  }
}
