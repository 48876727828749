.p-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 999;
  transition: opacity 0.5s, visibility 0s ease 1s;
  opacity: 0;
  visibility: hidden;
  &.is-active {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    transition-delay: 0s;
    opacity: 1;
    visibility: visible;
    animation: drawer 0.5s cubic-bezier(0, 0.35, 0.3, 0.8) 0s 1 both;
  }
  &__bg {
    background: rgba(0, 0, 0, 0.4);
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: absolute;
    width: 100%;
  }
  &__container {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 480px;
    max-height: 100%;
    overflow-y: auto;
    margin: 0 auto;
    padding: 0 $spacer;
    @include up(md) {
      padding: 0 $spacer-lg;
    }
    @include up(lg) {
      padding: 0;
    }
    &.--sm {
      max-width: 400px;
    }
    &.--lg {
      max-width: 900px;
    }
  }
  &__close {
    text-align: right;
    margin-bottom: $spacer-sm;
  }
  &__movie {
    background: #000;
  }
  .c-form {
    flex-direction: column;
    margin-bottom: $spacer;
    &__label {
      padding: 0 0 0.45rem;
      font-size: $font-size-caption;
      text-align: left;
      &::after {
        bottom: -0.15rem !important;
      }
    }
  }
  .p-card__body {
    overflow-y: scroll;
    max-height: 80vh;
  }
  .js-modal--close {
    cursor: pointer;
  }
}
