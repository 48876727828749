.p-slider {
  display: flex;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  padding-left: $spacer;
  &__item {
    flex: 0 0 100%;
    cursor: move;
    padding-right: $spacer;
  }
  @for $i from 0 through 5 {
    &.--p-#{$i} {
      .p-slider__item {
        padding-right: $spacer * $i;
      }
    }
  }
  @each $media-query in $media-queries {
    @for $i from 1 through 12 {
      @include up(#{$media-query}) {
        &.--#{$media-query}-#{$i} {
          .p-slider__item {
            flex-basis: decimal-ceil(percentage(1/$i*0.9), 5);
          }
        }
      }
    }
    @include up(#{$media-query}) {
      &.--#{$media-query} {
        &-reset {
          display: block;
        }
        @for $i from 1 through 12 {
          &-grid-#{$i} {
            padding: 0 !important;
            margin: 0 !important;
            display: grid;
            grid-template: 1fr / repeat(#{$i}, 1fr);
            grid-gap: $spacer;
            .p-slider__item {
              padding: 0;
              cursor: default !important;
            }
          }
        }
      }
    }
  }
}
// .c-container {
//   .p-slider {
//     @include down(md) {
//       margin-right: -$spacer-lg;
//       margin-left: -$spacer-lg;
//       padding-left: $spacer-lg !important;
//     }
//     @include down(sm) {
//       margin-right: -$spacer;
//       margin-left: -$spacer;
//       padding-left: $spacer !important;
//     }
//   }
// }
.disable-hover {
  pointer-events: none;
}
