$sample-width: 144px;
$sample-height: 81px;

.p-sample {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacer;
  background: $gray-2;
  border-radius: $border-radius;
  &__attribute {
    h1,h2,h3,h4 { font-size: $font-size-caption; }
    dl {
      display: flex;
      font-size: $font-size-small;
      color: $muted;
      dt {
        font-weight: $font-weight-normal;
        margin-right: $spacer-sm;
        &::after { content: ":"; }
      }
    }
  }
  &__movie {
    position: relative;
  }
  &__play {
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  &__bg {
    position: relative;
    border-radius: $border-radius-sm;
    background-size: cover;
    background-position: center;
    width: $sample-width;
    height: $sample-height;
    &:before {
      border-radius: $border-radius-sm;
      background: rgba(0,0,0,0.2) url(../../images/btn-play.svg);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      width: $sample-width;
      height: $sample-height;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
    }
  }
  &--more {
    &__container {
      display: none;
      &.is-active {
        display: block;
        .p-sample {
          margin-top: $spacer;
        }
      }
    }
    &__opener {
      text-align: center;
      margin-bottom: $spacer-sm;
      .is-active {
        display: none;
      }
    }
  }
}
