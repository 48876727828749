.u-font-family {
  &-sans-serif { font-family: $font-family-sans-serif; }
  &-serif { font-family: $font-family-serif; }
  &-curve { font-family: $font-family-curve; }
}
.u-font-style {
  &-italic { transform: skewX(-15deg); }
  &-normal { font-style: normal; }
}
.u-font-weight {
  &-thin { font-weight: $font-weight-thin !important; }
  &-normal { font-weight: $font-weight-normal !important; }
  &-bold { font-weight: $font-weight-bold !important; }
}
.u-font-pricing {
  font-family: $font-family-serif;
  font-weight: $font-weight-thin;
  font-size: $font-size-heading;
  font-style: italic;
  padding-left: .2rem;
  padding-right: .2rem;
  &.--lg { font-size: $font-size-hero; }
  &.--primary { color: $primary; }
  &.--pink { color: $pink-9; }
}
.u-font-size {
  @each $key, $value in $font-sizes {
    &-#{$key} {
      font-size: $value !important;
    }
  }
}
.u-font-bg {
  @each $key, $value in $colors {
    @for $i from 1 through 10 {
      &-#{$key}-#{$i} {
        background: linear-gradient(transparent 75%, map-get($value, $i) 0%)!important;
      }
    }
  }
}
