.p-ie {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 9997;
  &__container {
    padding: $spacer-lg;
    position: absolute;
    width: 100%;
    z-index: 9999;
  }
  &__bg {
    position: absolute;
    background: rgba($gray-10, 0.6);
    height: 100vh;
    width: 100%;
    z-index: 9998;
  }
  &__banner {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    display: inline-block;
    &:hover {
      border-color: $primary;
      background: $cyan-2;
    }
    img {
      max-width: 100%;
      width: 15rem;
    }
  }
  .c-container {
    width: 60rem;
  }
}
