
.p-journal {
  &__eyecatch {
    img {
      border-radius: $border-radius;
      margin-bottom: $spacer;
    }
  }
  &__heading {
    font-weight: $font-weight-bold;
    font-size: $font-size-sub-heading;
    margin-bottom: $spacer-sm;
  }
  &__description {
    color: $gray-8;
  }
  &__date {
    color: $gray-8;
  }
}
