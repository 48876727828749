.l-product {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: $spacer;
  grid-template-areas:
    "gallery"
    "actions"
    "body"
    "faq"
    "spec"
    "voices"
    "recommends"
    "back";
  &__gallery {
    grid-area: gallery;
    text-align: center;
    img, video {
      max-width: 100%;
    }
    @include down(md) {
      margin-left: -$spacer-lg;
      margin-right: -$spacer-lg;
    }
    @include down(sm) {
      margin-left: -$spacer;
      margin-right: -$spacer;
    }
    @include up(lg) {
      img, video {
        border-radius: $border-radius;
      }
    }
  }
  &__actions {
    grid-area: actions;
    z-index: 1;
  }
  &__body {
    grid-area: body;
  }
  &__scene {
    @extend .u-bg-gray-1;
    @extend .u-py-2;
    @extend .u-pr-2;
    margin-top: $spacer-lg;
    overflow: hidden;
    @include down(md) {
      margin-left: - $spacer-lg;
      margin-right: - $spacer-lg;
      padding-left: $spacer-lg;
      padding-right: $spacer-lg;
      margin-top: $spacer * 1.5;
    }
    @include down(sm) {
      margin-left: - $spacer;
      margin-right: - $spacer;
      padding-left: $spacer;
      padding-right: $spacer;
    }
    @include up(lg) {
      border-radius: $border-radius;
      padding: $spacer;
      .p-slider {
        grid-template: auto / auto;
        grid-gap: 0;
        .p-slider__item + .p-slider__item {
          &::before {
            content: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M17%2012L12%2017L7%2012%22%20stroke%3D%22%23344242%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3Cpath%20d%3D%22M17%208L12%2013L7%208%22%20stroke%3D%22%23344242%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
            display: block;
            text-align: center;
          }
        }
      }
    }
  }
  &__spec {
    grid-area: spec;
    margin-bottom: $spacer;
    .c-table tr th {
      min-width: 8rem;
      @include up(lg) { min-width: 10rem; }
    }
  }
  &__faq {
    grid-area: faq;
  }
  &__voices {
    grid-area: voices;
    overflow: hidden;
    margin-bottom: $spacer;
    @include down(md) {
      background: $gray-1;
      margin-left: - $spacer-lg;
      margin-right: - $spacer-lg;
      padding: $spacer-lg;
      .p-voice + .p-voice {
        margin-top: $spacer;
      }
    }
    @include down(sm) {
      margin-left: - $spacer;
      margin-right: - $spacer;
      padding: $spacer-lg $spacer;
    }
    @include up(lg) {
      .p-voice {
        box-shadow: none;
        padding: 0;
        border-radius: 0;
        + .p-voice {
          border-top: 1px dotted $border-color;
          margin-top: $spacer;
          padding-top: $spacer;
        }
      }
    }
  }
  &__recommends {
    grid-area: recommends;
  }
  &__back {
    grid-area: back;
    @include down(md) { display: none; }
  }
  @include up(lg) {
    padding-top: $spacer;
    padding-bottom: $spacer-lg !important;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, 1fr) 360px;
    grid-gap: $spacer-lg;
    grid-template-areas:
      "gallery actions"
      "body actions"
      "spec actions"
      "faq actions"
      "voices actions"
      "recommends actions"
      "back actions";
  }
  .p-product {
    position: relative;
    margin-bottom: $spacer;
    @include up(lg) {
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      grid-gap: $spacer-sm/2;
      .p-product__thumb { margin-bottom: $spacer-sm/2; }
      .p-product__name strong { font-size: $font-size-hero; }
    }
  }
}
