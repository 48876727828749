.u-flex {
  @for $i from 0 through 2 {
    &-grow-#{$i} {
      flex-grow: $i;
    }
  }
}
.u-align-items {
  @each $i in center,flex-start,flex-end {
    &-#{$i} {
      align-items: #{$i};
    }
    @each $media-query in $media-queries {
      &-#{$media-query}-#{$i} {
        @include up(#{$media-query}) { align-items: #{$i}; }
      }
    }
  }
}
.u-justify-content {
  @each $justify-content in $justify-contents {
    &-#{$justify-content} {
      justify-content: #{$justify-content} !important;
    }
  }
  @each $media-query in $media-queries {
    @include up(#{$media-query}) {
      @each $justify-content in $justify-contents {
        &-#{$media-query}-#{$justify-content} {
          justify-content: #{$justify-content} !important;
        }
      }
    }
  }
}
.u-d-flex {
  @each $media-query in $media-queries {
    &.--#{$media-query}-row {
      @include up(#{$media-query}) { flex-direction: row; }
    }
    &.--#{$media-query}-column {
      @include up(#{$media-query}) { flex-direction: column; }
    }
    &.--#{$media-query}-row-reverse {
      @include up(#{$media-query}) { flex-direction: row-reverse; }
    }
    &.--#{$media-query}-column-reverse {
      @include up(#{$media-query}) { flex-direction: column-reverse; }
    }
  }
  @each $i in center,flex-start,flex-end {
    &.--#{$i} {
      justify-content: #{$i};
    }
    @each $media-query in $media-queries {
      &.--#{$media-query}-#{$i} {
        @include up(#{$media-query}) {
          justify-content: #{$i};
        }
      }
    }
  }
}
