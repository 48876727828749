.l-line {
  &__merit {
    background: url(../../images/help/line/bg-diagonal.png);
    background-repeat: repeat;
    background-position: top center;
    padding: $spacer-lg 0;
    margin-bottom: $spacer-lg * 1.5;
    h2 {
      text-align: center;
    }
  }
  &__cta {
    background: url(../../images/help/line/bg-cta.jpg);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    padding: $spacer-lg * 2 0;
    text-align: center;
    .p-card {
      background-color: rgba(255,255,255,0.75);
    }
  }
}
.l-line-merit {
  margin-top: $spacer-lg;
  position: relative;
  &__border {
    position: absolute;
    top: 1rem;
    bottom: 1rem;
    right: 1rem;
    left: 1rem;
    border: 1px solid $teal-4;
  }
  &__container {
    background: $white;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 50%;
    grid-gap: $spacer-sm;
    grid-template-areas:
      "heading eyecatch"
      "content eyecatch";
    @include down(sm) {
      display: grid;
      grid-template-rows: auto 1fr;
      grid-template-columns: auto 40%;
      grid-gap: $spacer-sm;
      grid-template-areas:
        "heading eyecatch"
        "content content";
    }
    &.--inverse {
      display: grid;
      grid-template-rows: auto 1fr;
      grid-template-columns: auto 50%;
      grid-gap: $spacer-sm;
      grid-template-areas:
        "eyecatch heading"
        "eyecatch content";
      @include down(sm) {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 40% auto;
        grid-gap: $spacer-sm;
        grid-template-areas:
          "eyecatch heading"
          "content content";
      }
    }
  }
  &__heading {
    grid-area: heading;
    text-align: center;
    padding: $spacer-lg $spacer-lg $spacer;
    h3 {
      font-size: $font-size-title;
      margin-top: $spacer-sm;
    }
    @include down(sm) {
      text-align: left;
      h3 { font-size: $font-size-sub-heading; }
    }
  }
  &__content {
    grid-area: content;
    padding: 0 $spacer-lg $spacer-lg;
    @include down(sm) {
      padding: $spacer $spacer-lg $spacer-lg;
    }
  }
  &__eyecatch {
    grid-area: eyecatch;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &.--point-1 {
      background-image: url(../../images/help/line/eyecatch-point-1.jpg);
    }
    &.--point-2 {
      background-image: url(../../images/help/line/eyecatch-point-2.jpg);
    }
    &.--point-3 {
      background-image: url(../../images/help/line/eyecatch-point-3.jpg);
    }
    &.--point-4 {
      background-image: url(../../images/help/line/eyecatch-point-4.jpg);
    }
  }
  @include down(sm) {
    &__container {
        .l-line-merit__heading {
          padding: $spacer-lg $spacer $spacer $spacer-lg;
        }
      &.--inverse {
        .l-line-merit__heading {
          padding: $spacer-lg $spacer-lg $spacer $spacer;
        }
      }
    }
  }
}

