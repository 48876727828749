.p-campaign {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-gap: $spacer-sm;
  grid-template-areas:
    "thumb"
    "name"
    "description";
  &__thumb {
    grid-area: thumb;
    margin-bottom: $spacer-sm;
    img { border-radius: $border-radius; }
  }
  &__name {
    grid-area: name;
    align-self: center;
    font-family: $font-family-serif;
    font-weight: $font-weight-thin;
    font-size: $font-size-title * .9;
    h1,h2,h3 {
      font-size: $font-size-title * .9;
      font-weight: $font-weight-thin;
    }
  }
  &__description {
    grid-area: description;
  }
}
