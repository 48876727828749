.p-recommend {
  background: $gray-1;
  padding: $spacer-lg 0;
  &.--transparent {
    background: none;
  }
  @include up(lg) {
    padding: 0;
    background: none;
    + .p-recommend {
      margin-top: $spacer-lg;
    }
  }
  &__more {
    text-align: center;
    margin-top: $spacer;
  }
}
