.p-headline {
  padding: $spacer 0;
  &__item {
    font-size: $font-size-caption;
    display: flex;
    justify-content: center;
    align-items: center;
    time { margin-right: $spacer/2; }
    a { text-decoration: underline; }
  }

}
.p-headlines {
  @include up(md) {
    border: 1px solid $gray-1;
    border-radius: $border-radius;
    padding: $spacer $spacer $spacer-sm;
    background: $gray-1;
  }
  &__item {
    border-top: 1px dotted $border-color;
    @include up(md) {
      display: flex;
    }
    &:first-child {
      border-top: none;
    }
    padding: $spacer-sm 0;
    time {
      display: block;
      font-size: $font-size-caption;
      @include up(md) {
        font-size: $font-size-body;
        min-width: 8rem;
      }
    }
    a {
      text-decoration: underline;
      display: block;
    }
  }
}
