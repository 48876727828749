.l-voices {
  &__mv {
    text-align: center;
    background-color: #75cdd1;
  }
  &__list {
    padding: $spacer-lg 0;
    background-color: $cyan-1;
  }
}
