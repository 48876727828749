.p-timesale {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto;
  grid-gap: $spacer-sm;
  grid-template-areas:
    "duedate duedate"
    "thumb thumb"
    "name attribute"
    "description description"
    "status status";
  margin-bottom: $spacer;
  padding: $spacer;
  @extend .p-card;
  &__thumb {
    grid-area: thumb;
    img {
      border: 1px solid $gray-2;
      border-radius: $border-radius;
    }
  }
  &__name {
    grid-area: name;
    align-self: center;
    font-family: $font-family-serif;
    font-weight: $font-weight-thin;
    font-size: $font-size-title * 1.1;
    h1,h2,h3 { font-size: $font-size-caption; }
    &.--sm {
      font-size: $font-size-title;
    }
  }
  &__subtitle {
    font-weight: $font-weight-bold;
    font-size: $font-size-body * .9;
    margin-bottom: $spacer-sm;
    @include down(sm) {
      font-size: $font-size-body;
    }
  }
  &__attribute {
    grid-area: attribute;
    align-self: center;
  }
  &__description {
    grid-area: description;
  }
  &__status {
    grid-area: status;
    background: $gray-2;
    color: $gray-8;
    text-align: center;
    padding: .3rem $spacer;
    border-radius: $border-radius * .5;
    strong {
      font-weight: normal;
      padding: 0 .1rem;
    }
  }
  &__duedate {
    grid-area: duedate;
    font-size: $font-size-sub-heading;
    text-align: center;
    font-weight: bold;
    .c-label {
      margin-right: $spacer-sm;
      font-size: $font-size-body;
      padding: .2rem $spacer-sm;
    }
  }
  &.--pink {
    border: 1px solid $pink-4;
    .c-label {
      background: $pink-7;
      border-color: $pink-7;
    }
    .p-timesale__status {
      background: $pink-2;
      color: $pink-7;
    }
  }
  &.--cyan {
    border: 1px solid $cyan-4;
    .c-label {
      background: $cyan-7;
      border-color: $cyan-7;
    }
    .p-timesale__status {
      background: $cyan-2;
      color: $cyan-7;
    }
  }
  &.--orange {
    border: 1px solid $orange-4;
    .c-label {
      background: $orange-6;
      border-color: $orange-6;
    }
    .p-timesale__status {
      background: $orange-2;
      color: $orange-7;
    }
  }
  &.--violet {
    border: 1px solid $violet-4;
    .c-label {
      background: $violet-7;
      border-color: $violet-7;
    }
    .p-timesale__status {
      background: $violet-2;
      color: $violet-7;
    }
  }
  &.is-finished {
    .p-timesale__status {
      background: $gray-2 !important;
      color: $gray-8 !important;
    }
    .p-timesale__thumb {
      position: relative;
      &:before {
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        background: url(../../images/sales/nanairoweek/eyecatch-finished.png);
        background-size: cover;
        border-radius: $border-radius;
      }
    }
  }
}
