.l-live-endroll {
  &-mv {
    background-size: auto auto;
    background-color: rgba(199, 234, 236, 1);
    background-image: repeating-linear-gradient(45deg, transparent, transparent 8px, rgba(176, 225, 228, 1) 8px, rgba(176, 225, 228, 1) 9px );
    position: relative;
    margin-bottom: $spacer;
    @include up(lg) {
      margin-bottom: $spacer;
    }
    &__container {
      @include down(sm) {
        padding: 0 !important;
      }
    }
    &__copy {
      padding: $spacer*1.2 0 $spacer*1.2 $spacer-lg*3;
      @include up(md) {
        padding: $spacer-lg 0 $spacer-lg 25%;
      }
      @include up(lg) {
        padding: $spacer-lg 0 $spacer-lg 30%;
      }
    }
    &__footer {
      background: #FF828E;
      padding-top: $spacer-sm;
      padding-bottom: $spacer-sm;
      @include up(lg) {
        padding-top: $spacer;
        padding-bottom: $spacer;
      }
    }
    &__duedate {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-left: 30%;
      img {
        vertical-align: middle;
      }
    }
    &__year {
      padding-left: $spacer-sm;
      padding-right: $spacer-sm;
    }
    &__month {
      padding-right: $spacer-sm;
    }
    &__badge {
      position: absolute;
      top: 0;
      left: 0;
      width: 15rem;
      z-index: 1;
      @include down(sm) {
        width: 7rem;
      }
    }
    &__eyecatch {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      img {
        max-width: 40%;
        vertical-align: bottom;
      }
      @include down(sm) {
        left: -2.5rem;
      }
      @include up(lg) {
        img {
          max-width: 400px !important;
        }
      }
    }
  }
  &-cta {
    margin: $spacer auto;
    width: 50%;
    @include down(sm) {
      margin: $spacer-sm auto;
      width: 100%;
    }
    &__link {
      margin-bottom: $spacer-sm;
    }
    &__caution {
      @extend .u-list-caution;
      @extend .u-color-muted;
    }
  }
  &-problem {
    background: url(../../images/sales/early/bg-yellow-dotted.png);
    padding-top: $spacer-lg*1.5;
    position: relative;
    @include down(sm) {
      padding-bottom: $spacer;
    }
    .--strength{
      font-size: $font-size-hero;
      margin-bottom: $spacer-lg;
      letter-spacing: .1rem;
      font-family: $font-family-serif;
      line-height: 3.5rem;
    }
    h2 {
      margin-bottom: $spacer;
      @include up(md) {
        margin-bottom: 0;
      }
    }
    h3 {
      img {
        width: 25%;
        position: relative;
        bottom: -1rem;
      }
      margin-bottom: $spacer-lg;
      border-top: 0;
      border-right: 0;
      border-bottom-width: 1px;
      border-style: solid;
      border-image-slice: 1;
      border-image-source: linear-gradient(to right, #f9fadc 0% 20%, #23B6BC 20% 100%);
    }
    ul {
      padding-left: 0;
      margin-bottom: $spacer-lg;
      li {
        font-size: $font-size-heading;
        list-style: none;
        background-image: url(../../images/sales/early/icon-check.svg);
        background-repeat: no-repeat;
        background-position: top .4rem left 0;
        background-size: 1.8rem;
        padding-left: 3rem;
        margin-top: $spacer;
      }
    }
    strong {
      &.__marker{
        background: $white;
        color: $cyan-7;
      }
    }
  }
  &-problem-footer {
    text-align: center;
    position: relative;
    top: -$spacer;
  }
  &-data {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: $spacer;
    grid-template-areas:
      "graph graph"
      "heading list";
    @include down(md) {
      grid-template-areas:
        "heading graph"
        "list graph";
      grid-template-columns: 4fr 5fr;
    }
    @include down(sm) {
      grid-gap: 0;
      display: flex;
      flex-direction: column;
    }
    &__graph {
      grid-area: graph;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    &__heading {
      grid-area: heading;

    }
    &__list {
      grid-area: list;
      @include up(lg) {
        font-size: $font-size-heading*.9;
      }
    }
  }
  &-plans {
    position: relative;
    h2 {
      margin-top: $spacer-lg;
      text-align: center;
      font-size: $font-size-hero;
      font-family: $font-family-serif;
      margin-bottom: $spacer-lg;
      small {
        display: block;
        margin-bottom: $spacer;
      }
    }
    .iframe-wrapper {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      margin-bottom: $spacer-lg;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-plan {
    border: 5px solid $gray-1;
    padding: $spacer;
    margin-bottom: $spacer-lg;
    @include down(sm) {
      margin-bottom: $spacer;
    }
    &__eyecatch {
      position: relative;
      margin-bottom: $spacer;
    }
    &__badge {
      position: absolute;
      bottom: -7rem;
      right: 0;
      width: 12rem;
      @include down(md) {
        width: 10rem;
        bottom: -5rem;
      }
    }
    &__heading {
      font-size: $font-size-hero*1.2;
      font-family: $font-family-serif;
      color: $red-6;
    }
    &__copy {
      font-size: $font-size-sub-heading;
      font-family: $font-family-serif;
      color: $cyan-8;
      margin-bottom: $spacer;
    }
    &__description {
      @extend .l-article;
      strong {
        color: #FF828E;
        background-color: #fcffa0;
        span {
          font-size: $font-size-hero;
        }
      }
    }
  }
  &-apply {
    background-image: url(../../images/sales/early/bg-apply.svg), repeating-linear-gradient(45deg, transparent, transparent 8px, rgba(199, 234, 236, .8) 8px, rgba(199, 234, 236, .8) 9px );
    background-position: top center, top center;
    background-repeat: repeat-x, repeat;
    background-color: #8fd1d6;
    position: relative;
    .u-list-cautions {
      color: $gray-9;
      margin-bottom: $spacer-lg;
    }
    h2 {
      margin-top: $spacer-lg;
      text-align: center;
      color: $white;
      font-size: $font-size-hero;
      font-family: $font-family-serif;
      margin-bottom: $spacer-lg;
      small {
        display: block;
        margin-bottom: $spacer;
      }
    }
    .p-card {
      font-size: $font-size-sub-heading;
      margin-bottom: $spacer-lg;
      &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  &-faq {
    @extend .c-section;
    position: relative;
    h2 {
      margin-top: $spacer-lg;
      text-align: center;
      font-size: $font-size-hero;
      font-family: $font-family-serif;
      margin-bottom: $spacer-lg;
      small {
        display: block;
        margin-bottom: $spacer;
      }
    }
  }
}
