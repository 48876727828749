// Right, Left, Top, Bottom
@each $key, $value in $directions {
  @for $i from 0 through 5 {
    .u-m#{$value}-#{$i} {
      margin-#{$key}: #{$i * $spacer} !important;
    }
    .u-p#{$value}-#{$i} {
      padding-#{$key}: #{$i * $spacer} !important;
    }
  }
  .u-m#{$value}-auto {
    margin-#{$key}: auto !important;
  }
}
// Vertical, Horizontal
@each $key, $value in $multi-directions {
  @for $i from 0 through 5 {
    .u-m#{$value}-#{$i} {
      @if $key == vertical {
        margin-top: #{$i * $spacer} !important;
        margin-bottom: #{$i * $spacer} !important;
      } @else {
        margin-left: #{$i * $spacer} !important;
        margin-right: #{$i * $spacer} !important;
      }
    }
    .u-p#{$value}-#{$i} {
      @if $key == vertical {
        padding-top: #{$i * $spacer} !important;
        padding-bottom: #{$i * $spacer} !important;
      } @else {
        padding-left: #{$i * $spacer} !important;
        padding-right: #{$i * $spacer} !important;
      }
    }
  }
}
// With Media Query
@each $media-query in $media-queries {
  @include up(#{$media-query}) {
    @for $i from 0 through 5 {
      @each $key, $value in $directions {
        .u-m#{$value}-#{$media-query}-#{$i} {
          margin-#{$key}: #{$i * $spacer} !important;
        }
        .u-p#{$value}-#{$media-query}-#{$i} {
          padding-#{$key}: #{$i * $spacer} !important;
        }
      }
      @each $key, $value in $multi-directions {
        .u-m#{$value}-#{$media-query}-#{$i} {
          @if $key == vertical {
            margin-top: #{$i * $spacer} !important;
            margin-bottom: #{$i * $spacer} !important;
          } @else {
            margin-right: #{$i * $spacer} !important;
            margin-left: #{$i * $spacer} !important;
          }
        }
        .u-p#{$value}-#{$media-query}-#{$i} {
          @if $key == vertical {
            padding-top: #{$i * $spacer} !important;
            padding-bottom: #{$i * $spacer} !important;
          } @else {
            padding-right: #{$i * $spacer} !important;
            padding-left: #{$i * $spacer} !important;
          }
        }
      }
    }
  }
  @include up(#{$media-query}) {
    @each $key, $value in $directions {
      .u-m#{$value}-#{$media-query}-auto {
        margin-#{$key}: auto !important;
      }
    }
  }
}
