.p-nps {
  width: 100%;
  display: flex;
  justify-content:  space-between;
  align-items: center;
  overflow: hidden;
  margin-top: $spacer-sm;
  &__left, &__right {
    font-weight: bold;
    font-size: $font-size-caption;
    @include down(sm) {
      display: none;
    }
  }
  &__bullet {
    text-align: center;
    border: 1px solid $cyan-6;
    height: 3rem;
    width: 3rem;
    border-radius: 100%;
    color: $cyan-6;
    display: inline-block;
    position: relative;
    transition: background-color 500ms;
    text-align: center;
    cursor: pointer;
    line-height: 3rem;
    font-size: $font-size-caption;
    &.is-active  {
      color: $white;
      background-color: $cyan-6;
    }
  }
}
