.l-refund {
  &__mv {
    padding: $spacer 0;
    background-image: url(../../images/features/refund/eyecatch-mv.png);
    background-position: right top;
    background-size: contain;
    margin-bottom: $spacer;
    h1 {
      width: 40%;
      margin-bottom: $spacer;
    }
    p {
      font-family: $font-family-serif;
      margin-bottom: $spacer;
      font-size: $font-size-sub-heading;
      strong {
        font-weight: normal;
        color: $cyan-8;
      }
    }
    @include down(md) {
      background-size: 60%;
      h1 { width: 50%; }
    }
    @include down(sm) {
      margin-bottom: 0;
      background-size: 50%;
      margin-top: $spacer;
      h1 { width: 72%; margin-bottom: $spacer; }
      p {
        font-size: $font-size-body;
        font-family: $font-family-sans-serif;
        br {
          display: none;
        }
      }
    }
  }
  &__features {
    background-image: url(../../images/features/refund/bg-features.jpg);
    background-size: cover;
    padding: $spacer-lg 0;
  }
  &__feature {
    background: rgba(255,255,255, 0.8);
    border-radius: $border-radius;
    padding: $spacer-lg;
    + .l-refund__feature {
      margin-top: $spacer-lg;
    }
    h2 {
      font-family: $font-family-serif;
      font-size: $font-size-hero;
      font-weight: normal;
      margin-bottom: $spacer;
      background-image: url(../../images/features/refund/icon-refund.svg);
      background-size: 4.5rem;
      padding-left: 5.5rem;
      @include down(sm) {
        font-size: $font-size-heading;
      }
    }
    p {
      strong {
        color: $cyan-8;
      }
      @include down(sm) {
        margin-bottom: $spacer;
      }
    }
    dl {
      padding: $spacer;
      border: 1px solid $gray-4;
      border-radius: $border-radius;
      color: $gray-10;
      ul li a {
        color: $gray-10;
      }
      dt {
        margin-bottom: $spacer-sm;
      }
    }
  }
  &__flow {
    padding: $spacer-lg 0;
    header {
      background-image: url(../../images/features/refund/eyecatch-flow.png);
      background-size: contain;
      padding-top:  $spacer-lg * 2;
      height: 100%;
      h2, p {
        padding-left: $spacer-lg;
        padding-right: $spacer-lg;
      }
      h2 {
        font-family: $font-family-serif;
        font-weight: normal;
        font-size: $font-size-hero * 1.4;
      }
      @include down(md) {
        background-size: contain;
        background-position: right center;
        padding-top: 0;
        padding: $spacer-lg 0;
        h2, p {
          padding: 0;
        }
        h2 {
          font-size: $font-size-hero;
        }
        p {
          font-size: $font-size-caption;
        }
      }
    }
    ol {
      padding-left: 0;
      list-style-type: none;
      @include down(md) {
        margin-top: $spacer;
      }
      li {
        + li { margin-top: $spacer; }
        &:nth-child(2) dl { background: $cyan-2; border-color: $cyan-3; }
        &:nth-child(3) dl { background: $cyan-3; border-color: $cyan-4; }
      }
    }
    dl {
      background: $cyan-1;
      border: 1px solid $cyan-2;
      padding: $spacer;
      border-radius: 0 $border-radius * 2 0 $border-radius * 2;
      text-align: center;
      dt {
        font-family: $font-family-serif;
        font-weight: normal;
        font-size: $font-size-sub-heading;
        margin-bottom: $spacer-sm;
        color: $cyan-9;
        img {
          margin-right: $spacer-sm;
          display: inline-block;
          margin-bottom: 0;
          vertical-align: text-top;
        }
        @include up(md) {
          br { display: none; }
        }
      }
      dd {
        font-size: $font-size-caption;
        @include down(md) {
          text-align: left;
          br { display: none; }
        }
      }
    }
  }
  &__faq {
    background-image: url(../../images/features/refund/bg-faq.png);
    background-repeat: repeat;
    padding: $spacer-lg 0 $spacer;
    h2 {
      font-family: $font-family-serif;
      text-align: center;
      font-weight: normal;
      font-size: $font-size-hero;
      margin-bottom: $spacer;
    }
    p {
      text-align: center;
      margin-bottom: $spacer-lg;
      @include down(md) {
        text-align: left;
        margin-bottom: $spacer;
        br { display: none; }
      }
    }
    dl {
      background: rgba(255,255,255,0.8);
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
      border-radius: $border-radius;
      padding: $spacer;
      height: 100%;
      dt {
        background-image: url(../../images/features/refund/icon-question.svg);
        background-size: 3rem;
        padding-left: 4rem;
        padding-bottom: $spacer / 2;
        margin-bottom: $spacer;
        border-bottom: 1px solid $border-color;
      }
    }
  }
}
