.toastify {
  padding: $spacer $spacer-lg;
  color: $white;
  display: inline-block;
  background: $gray-5;
  position: fixed;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  border-radius: $border-radius-sm;
  cursor: pointer;
  text-decoration: none;
  z-index: 9999;
  top: 1rem;
  &.on { opacity: 1; }
  &.toastify-right { right: $spacer; }
  &.toastify-left { left: $spacer; }
  &.toastify-top { top: - $spacer*15; }
  &.toastify-bottom { bottom: -$spacer*15; }
  &.toastify-rounded { border-radius: $border-rounded; }
  &.toastify-center {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-width: fit-content;
  }
  @include down(md) {
    &.toastify-right, &.toastify-left {
      margin-left: auto;
      margin-right: auto;
      margin-top: -$spacer;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      max-width: 100%;
      text-align: center;
      border-radius: 0;
      padding: $spacer * 1.46 $spacer-lg;
    }
  }
  &.is-danger {
    background: $danger;
  }
  &.is-success {
    background: $success;
  }
}
.toast-close { opacity: 0.4; padding: 0 $spacer-sm; }
.toastify-avatar {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 $spacer-sm;
  border-radius: $border-radius-sm;
}
