.p-flow {
  background-image: url(../../images/help/flow/bg-rail.svg);
  background-size: contain;
  background-position: top 3rem center;
  @include down(md) {
    background-image: url(../../images/help/flow/bg-rail-sm.svg);
    background-size: contain;
    background-position: top 3rem center;
  }
  &__duedate {
    position: absolute;
    top: -$spacer;
    left: $spacer-lg;
  }
  &__heading {
    font-size: $font-size-title;
    margin-bottom: $spacer;
  }
  &__content {
    text-align: left;
    > * + * {
      margin-top: $spacer;
    }
  }
  &__caution {
    @include down(md) {
      background: rgba(255,255,255,0.4);
      padding: $spacer;
    }
  }
  &__item {
    position: relative;
    background: $white;
    border-radius: $border-radius;
    padding: $spacer-lg;
    margin-top: $spacer-lg;
    margin-bottom: $spacer-lg;
    text-align: center;
    &.--blank {
      background: none;
      padding: 0;
    }
  }
  &__email {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include down(md) {
      justify-content: center;
    }
  }
  &__delivery {
    list-style-type: none;
    padding: none;
    display: flex;
    flex-direction: column;
    > li {
      &:first-child {
        align-self: flex-start;
      }
      &:nth-child(2) {
        align-self: flex-end;
      }
    }
  }
}



