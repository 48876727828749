.l-header {
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 3;
  flex-shrink: 0;
  &__brand {
    margin-left: $spacer-sm;
  }
  &__nav, &__actions {
    display: flex;
    align-items: center;
  }
  &__nav {
    margin-left: $spacer;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    a {
      @include link-colors($black,$primary)
      padding: $spacer-sm;
    }
    @include down(md) { display: none; }
  }
  &__actions {
    margin-left: auto;
    @include up(lg) { margin-right: $spacer-sm; }
  }
  &__shortcut {
    @include up(md) {  padding: 0 $spacer-sm/2; }
    &.--inquiry-icon {
      @include down(sm) {
        display: none;
      }
    }
    //カートが空のときカートのアイコンを非表示にする
    // &.--cart-icon {
    //   display: none;
    //   &.is-active {
    //     display: block;
    //   }
    // }
  }
  &__drawer {
    margin-left: $spacer-sm;
    @include up(lg) { display: none; }
    position: relative;
    &.is-fixed {
      @keyframes topToBottom {
        0% {
         transform: translateY(-30px);
        }
        100%{
         transform: translateY(0);
        }
      }
      position: fixed;
      top: 0;
      right: 0;
      animation: topToBottom .5s;
    }
  }
  &-admin {
    background: $gray-10;
    color: $white;
    padding: $spacer-sm;
    font-size: $font-size-small;
    text-align: center;
  }
}
