.l-modification {
  &__question {
    text-align: center;
    background-image: url(../../images/features/modification/bg-question.png);
    background-size: cover;
    padding-top: $spacer;
    padding-bottom: $spacer-lg;
    overflow: hidden;
    img { position: relative; }
    .l-modification__illust {
      position: absolute;
      left: -15rem;
      bottom: -5rem;
      width: 50%;
      &.--right {
        position: absolute;
        left: auto;
        right: -12rem;
        top: -3rem;
      }
    }
  }
  &__mv {
    .c-container {
      background-image: url(../../images/features/modification/eyecatch-mv.jpg);
      background-size: 60%;
      background-position: right center;
      padding-top: $spacer-lg;
      padding-bottom: $spacer-lg;
      margin-bottom: $spacer-lg;
      @include down(sm) {
        background-image: url(../../images/features/modification/eyecatch-mv-sm.jpg);
        background-size: contain;
        margin-bottom: 0;
      }
    }
    h2 {
      margin-bottom: $spacer;
    }
    p {
      display: inline;
      background-color: $white;
      margin-bottom: $spacer-lg;
      font-family: $font-family-serif;
      @include up(md) {
        font-size: $font-size-sub-heading;
      }
    }
  }
  &__point {
    margin-bottom: $spacer-lg;
    p {
      font-size: $font-size-caption;
    }
    img {
      width: 200px !important;
    }
    .p-card{
      background: $orange-1;
      @include down(sm) {
        border: solid 1px $orange-5;
      }
    }
  }
  &__comparison {
    .c-container {
      background-image: url(../../images/features/modification/eyecatch-comparison.png);
      background-repeat: no-repeat;
      background-size: contain;
      @include down(md) {
        background: none;
        text-align: center;
        p {
          text-align: left;
        }
      }
    }
    h2, h3, p {
      margin-bottom: $spacer;
      padding-left: $spacer-lg;
      @include down(md) {
        padding-left: 0;
      }
    }
  }
  &__voices {
    padding: $spacer-lg 0;
    background-image: url(../../images/features/modification/bg-dotted.svg);
    background-repeat: repeat;
    background-position: center;
  }
  &__refund {
    text-align: center;
    padding: $spacer-lg 0;
    h2,h3 {
      margin-bottom: $spacer-lg;
    }
    p {
      @include down(sm) {
        text-align: left;
      }
    }
  }
}
