.p-card {
  background: $white;
  border-radius: $border-radius;
  &.--shadow {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, .05);
  }
  &.--border {
    border: 1px solid $border-light-color;
  }
  &__header {
    display: flex;
    align-items: center;
    padding: $spacer * 1.2;
    border-bottom: 1px solid $border-light-color;
    border-radius: $border-radius $border-radius 0 0;
    h1,h2,h3,h4 { font-size: $font-size-sub-heading; }
  }
  &__body {
    padding: $spacer * 1.2;
    @include up(lg) { padding: $spacer-lg; }
    .c-form { margin-bottom: $spacer * 1.2; }
    &.l-article { padding-bottom: $spacer; }
  }
  &__footer {
    display: flex;
    padding: $spacer * 1.2;
    background: $gray-1;
    border-top: 1px solid $gray-4;
    border-radius: 0 0 $border-radius $border-radius;
  }
  &__link {
    @extend %button;
    padding: $spacer * 1.2;
    border: none;
    border-radius: 0 0 $border-radius $border-radius;
    text-align: center;
    display: block;
    @each $key, $value in $button-colors {
      &.--#{$key} {
        background: $value;
        border-color: $value;
        color: $white;
        .c-icon path { fill: $value; }
        &:hover {
          background: darken($value,7%);
          border-color: darken($value,7%);
          .c-icon path { fill: darken($value,7%); }
        }
      }
    }
  }
  &__heading {
    display: flex;
    padding-bottom: $spacer-sm;
    border-bottom: 1px solid $border-light-color;
    margin-bottom: $spacer;
    h1, h2, h3, h4 {
      color: $gray-9;
      font-size: $font-size-sub-heading;
    }
  }
  &.--sm {
    .p-card__body {
      padding: $spacer;

    }
  }
}
