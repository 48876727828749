$datepicker-width: 307.875px;

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  animation: none;
  border: 0;
  font-size: $font-size-body;
  line-height: 2.4rem;
  border-radius: $border-radius;
  position: absolute;
  width: $datepicker-width;
  box-sizing: border-box;
  touch-action: manipulation;
  background: $white;
  box-shadow: 1px 0 0 $border-color, -1px 0 0 $border-color, 0 1px 0 $border-color, 0 -1px 0 $border-color, 0 3px 13px rgba(0, 0, 0, 0.08);
  &.open,
  &.inline {
    opacity: 1;
    max-height: 640px;
    visibility: visible;
  }
  &.open {
    display: inline-block;
    z-index: 99999;
  }
  &.animate.open {
    animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  &.inline {
    display: block;
    position: relative;
    top: 2px;
  }
  &.static {
    position: absolute;
    top: calc(100% + 2px);
    &.open {
      z-index: 999;
      display: block;
    }
  }
  &.multiMonth .flatpickr-days .dayContainer {
    &:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
    &:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
      -webkit-box-shadow: -2px 0 0 $border-color, 5px 0 0 $border-color;
      box-shadow: -2px 0 0 $border-color, 5px 0 0 $border-color;
    }
  }
  .hasWeeks .dayContainer,
  .hasTime .dayContainer {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .hasWeeks .dayContainer {
    border-left: 0;
  }
  &.showTimeInput.hasTime .flatpickr-time {
    height: 40px;
    border-top: 1px solid $border-color;
  }
  &.noCalendar.hasTime .flatpickr-time {
    height: auto;
  }
  &:before,
  &:after {
    position: absolute;
    display: block;
    pointer-events: none;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    left: 22px;
  }
  &.rightMost {
    &:before,
    &:after {
      left: auto;
      right: 22px;
    }
  }
  &:before {
    border-width: 5px;
    margin: 0 -5px;
  }
  &:after {
    border-width: 4px;
    margin: 0 -4px;
  }
  &.arrowTop {
    &:before,
    &:after {
      bottom: 100%;
    }
    &:before {
      border-bottom-color: $border-color;
    }
    &:after {
      border-bottom-color: $primary;
    }
  }
  &.arrowBottom {
    &:before,
    &:after {
      top: 100%;
    }
    &:before {
      border-top-color: $border-color;
    }
    &:after {
      border-top-color: $white;
    }
  }
  &:focus {
    outline: 0;
  }
}
.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}
.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  .flatpickr-month {
    background: $primary;
    border-radius: $border-radius $border-radius 0 0;
    color: $white !important;
    fill: $white !important;
    height: 34px;
    line-height: 1;
    text-align: center;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .flatpickr-prev-month,
  .flatpickr-next-month {
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 34px;
    padding: 10px;
    z-index: 3;
    color: $white;
    fill: $white;
  }
  .flatpickr-prev-month.flatpickr-disabled,
  .flatpickr-next-month.flatpickr-disabled {
    display: none;
  }
  .flatpickr-prev-month i,
  .flatpickr-next-month i {
    position: relative;
  }
  .flatpickr-prev-month.flatpickr-prev-month {
    left: 0;
  }
  .flatpickr-next-month {
    &.flatpickr-prev-month {
      left: 0;
      right: 0;
    }
    &.flatpickr-next-month {
      right: 0;
    }
  }
  .flatpickr-prev-month:hover,
  .flatpickr-next-month:hover {
    color: $gray-3;
  }
  .flatpickr-prev-month:hover svg,
  .flatpickr-next-month:hover svg {
    fill: $white;
  }
  .flatpickr-prev-month svg,
  .flatpickr-next-month svg {
    width: 14px;
    height: 14px;
  }
  .flatpickr-prev-month svg path,
  .flatpickr-next-month svg path {
    -webkit-transition: fill 0.1s;
    transition: fill 0.1s;
    fill: inherit;
  }
}
.numInputWrapper {
  position: relative;
  height: auto;
  input,
  span {
    display: inline-block;
  }
  input {
    width: 100%;
    &::-ms-clear {
      display: none;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }
  span {
    position: absolute;
    right: 0;
    width: 14px;
    padding: 0 4px 0 2px;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
    //border: 1px solid rgba(57, 57, 57, 0.15);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    &:hover {
      background: none;
    }
    &:active {
      background: none;
    }
    &:after {
      display: block;
      content: "";
      position: absolute;
    }
    &.arrowUp {
      top: 0;
      border-bottom: 0;
      &:after {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid rgba(255, 255, 255, 0.5);
        top: 26%;
      }
    }
    &.arrowDown {
      top: 50%;
      &:after {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid rgba(255, 255, 255, 0.5);
        top: 40%;
      }
    }
    svg {
      width: inherit;
      height: auto;
      path {
        fill: rgba(0, 0, 0, 0.2);
      }
    }
  }
  &:hover {
    background: none;
    span {
      opacity: 1;
    }
  }
}
.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  span.cur-month {
    font-family: inherit;
    font-weight: 700;
    color: inherit;
    display: inline-block;
    margin-left: 0.5ch;
    padding: 0;
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
  .numInputWrapper {
    width: 6ch;
    width: 7ch\0;
    display: inline-block;
    span {
      &.arrowUp:after {
        border-bottom-color: rgba(255, 255, 255, 0.9);

      }
      &.arrowDown:after {
        border-top-color: rgba(255, 255,255, 0.9);
      }
    }
  }
  input.cur-year {
    background: transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
    cursor: text;
    padding: 0 0 0 0.5ch;
    margin: 0;
    display: inline-block;
    font-size: $font-size-sub-heading;
    font-family: inherit;
    font-weight: $font-weight-normal;
    line-height: inherit;
    height: auto;
    border: 0;
    border-radius: 0;
    vertical-align: initial;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:focus {
      outline: 0;
    }
    &[disabled] {
      font-size: 100%;
      color: rgba(0, 0, 0, 0.5);
      background: transparent;
      pointer-events: none;
      &:hover {
        font-size: 100%;
        color: rgba(0, 0, 0, 0.5);
        background: transparent;
        pointer-events: none;
      }
    }
  }
  .flatpickr-monthDropdown-months {
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    color: inherit;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    font-weight: $font-weight-normal;
    height: auto;
    font-size: $font-size-body;
    line-height: inherit;
    margin: -1px 0 0 0;
    outline: none;
    padding: 0 0 0 0.5ch;
    position: relative;
    vertical-align: initial;
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: auto;
    &:focus,
    &:active {
      outline: none;
    }
    &:hover {
      background: none;
    }
    .flatpickr-monthDropdown-month {
      background-color: transparent;
      outline: none;
      padding: 0;
    }
  }
}
.flatpickr-weekdays {
  background: $primary;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
  padding: $spacer-sm;
  .flatpickr-weekdaycontainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: $cyan-9;
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}
.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}
.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: $datepicker-width;
  &:focus {
    outline: 0;
  }
}
.dayContainer {
  padding: $spacer-sm;
  outline: 0;
  text-align: left;
  width: $datepicker-width;
  min-width: $datepicker-width;
  max-width: $datepicker-width;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
  +.dayContainer {
    -webkit-box-shadow: -1px 0 0 $border-color;
    box-shadow: -1px 0 0 $border-color;
  }
}
.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  &.inRange,
  &.prevMonthDay.inRange,
  &.nextMonthDay.inRange,
  &.today.inRange,
  &.prevMonthDay.today.inRange,
  &.nextMonthDay.today.inRange,
  &:hover,
  &.prevMonthDay:hover,
  &.nextMonthDay:hover,
  &:focus,
  &.prevMonthDay:focus,
  &.nextMonthDay:focus {
    cursor: pointer;
    outline: 0;
    background: $gray-3;
    border-color: $gray-3;
  }

  &.today {
    border-color: transparent;
    background: $gray-2;

    &:hover,
    &:focus {
      border-color: transparent;
      background: $gray-3;
      color: $white;
    }
  }
  &.selected,
  &.startRange,
  &.endRange,
  &.selected.inRange,
  &.startRange.inRange,
  &.endRange.inRange,
  &.selected:focus,
  &.startRange:focus,
  &.endRange:focus,
  &.selected:hover,
  &.startRange:hover,
  &.endRange:hover,
  &.selected.prevMonthDay,
  &.startRange.prevMonthDay,
  &.endRange.prevMonthDay,
  &.selected.nextMonthDay,
  &.startRange.nextMonthDay,
  &.endRange.nextMonthDay {
    background: $primary;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: $white;
    border-color: $primary;
  }
  &.selected.startRange,
  &.startRange.startRange,
  &.endRange.startRange {
    border-radius: 50px 0 0 50px;
  }
  &.selected.endRange,
  &.startRange.endRange,
  &.endRange.endRange {
    border-radius: 0 50px 50px 0;
  }
  &.selected.startRange+.endRange:not(:nth-child(7n+1)),
  &.startRange.startRange+.endRange:not(:nth-child(7n+1)),
  &.endRange.startRange+.endRange:not(:nth-child(7n+1)) {
    -webkit-box-shadow: -10px 0 0 $primary;
    box-shadow: -10px 0 0 $primary;
  }
  &.selected.startRange.endRange,
  &.startRange.startRange.endRange {
    border-radius: 50px;
  }
  &.inRange {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 $border-color, 5px 0 0 $border-color;
    box-shadow: -5px 0 0 $border-color, 5px 0 0 $border-color;
  }
  &.flatpickr-disabled {
    color: $gray-1;
    background: transparent;
    border-color: transparent;
    cursor: default;
    &:hover {
      color: $gray-1;
      background: transparent;
      border-color: transparent;
      cursor: default;
    }
  }
  &.prevMonthDay,
  &.nextMonthDay {
    color: $gray-1;
    background: transparent;
    border-color: transparent;
    cursor: default;
  }
  &.notAllowed {
    color: $gray-1;
    background: transparent;
    border-color: transparent;
    cursor: default;
    &.prevMonthDay,
    &.nextMonthDay {
      color: $gray-1;
      background: transparent;
      border-color: transparent;
      cursor: default;
    }
  }
  &.flatpickr-disabled {
    cursor: not-allowed;
    color: rgba(57, 57, 57, 0.1);
    &:hover {
      cursor: not-allowed;
      color: rgba(57, 57, 57, 0.1);
    }
  }
  &.week.selected {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 $primary, 5px 0 0 $primary;
    box-shadow: -5px 0 0 $primary, 5px 0 0 $primary;
  }
  &.hidden {
    visibility: hidden;
  }
}
.rangeMode .flatpickr-day {
  margin-top: 1px;
}
.flatpickr-weekwrapper {
  float: left;
  .flatpickr-weeks {
    padding: 0 12px;
    -webkit-box-shadow: 1px 0 0 $border-color;
    box-shadow: 1px 0 0 $border-color;
  }
  .flatpickr-weekday {
    float: none;
    width: 100%;
    line-height: 28px;
  }
  span.flatpickr-day {
    display: block;
    width: 100%;
    max-width: none;
    color: $gray-1;
    background: transparent;
    cursor: default;
    border: none;
    &:hover {
      display: block;
      width: 100%;
      max-width: none;
      color: $white;
      background: transparent;
      cursor: default;
      border: none;
    }
  }
}
.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}
.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  .numInputWrapper {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 40%;
    height: 40px;
    float: left;
    span {
      &.arrowUp:after {
        border-bottom-color: #393939;
      }
      &.arrowDown:after {
        border-top-color: #393939;
      }
    }
  }
  &.hasSeconds .numInputWrapper {
    width: 26%;
  }
  &.time24hr .numInputWrapper {
    width: 49%;
  }
  input {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    text-align: center;
    margin: 0;
    padding: 0;
    height: inherit;
    line-height: inherit;
    color: #393939;
    font-size: 14px;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &.flatpickr-hour {
      font-weight: bold;
    }
    &.flatpickr-minute,
    &.flatpickr-second {
      font-weight: 400;
    }
    &:focus {
      outline: 0;
      border: 0;
    }
  }
  .flatpickr-time-separator {
    height: inherit;
    float: left;
    line-height: inherit;
    color: #393939;
    font-weight: bold;
    width: 2%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .flatpickr-am-pm {
    height: inherit;
    float: left;
    line-height: inherit;
    color: #393939;
    font-weight: bold;
    width: 2%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    outline: 0;
    width: 18%;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
  }
  input:hover,
  .flatpickr-am-pm:hover,
  input:focus,
  .flatpickr-am-pm:focus {
    background: #eee;
  }
}
.flatpickr-input[readonly] {
  cursor: pointer;
}
@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
