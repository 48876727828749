.p-note {
  $note-font-size-base: 1.5rem;
  $note-font-size-h2: 2.2rem;
  $note-font-size-h3: 1.9rem;
  $note-font-size-h4: 1.7rem;
  $note-font-size-h5: 1.5rem;
  line-height: 2;
  font-size: $note-font-size-base;
  font-feature-settings: $font-feature-settings;
  letter-spacing: $letter-spacing;
  p, ul, ol, dl, table, > img {
    margin-bottom: $spacer-lg;
  }
  h2 {
    font-size: $note-font-size-h2;
    line-height: 1.5;
    border-bottom: 1px solid $border-color;
    padding-bottom: $spacer;
    margin-bottom: $spacer-lg;
  }
  h3 {
    font-size: $note-font-size-h3;
    margin-bottom: $spacer;
  }
  h4 {
    font-size: $note-font-size-h4;
    margin-bottom: $spacer-sm;
  }
  h5 {
    font-size: $note-font-size-h5;
  }
  img {
    border-radius: $border-radius;
  }
  table {
    width: 100%;
    td,th {
      border: 1px solid $table-border-color;
      padding: $spacer-sm $spacer;
      vertical-align: top;
      > * + * { margin-top: $spacer-sm; }
    }
    th { font-weight: $font-weight-normal; }
  }
}
