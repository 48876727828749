.p-shortcut {
  border-radius: $border-radius;
  border: 1px solid $border-color;
  background: $white;
  display: flex;
  margin-bottom: $spacer;
  &:hover {
    color: $body;
    text-decoration: none;
  }
  &__eyecatch {
    width: 12rem;
    background-position: center;
    background-size: cover;
    &.--profile { background-image: url(../../images/eyecatch-shortcut-profile.jpg); }
    &.--opening { background-image: url(../../images/eyecatch-shortcut-opening.jpg); }
    &.--endroll { background-image: url(../../images/eyecatch-shortcut-endroll.jpg); }
    border-radius: $border-radius 0 0 $border-radius;
  }
  &__content {
    width: 100%;
    padding: $spacer-sm;
  }
  &__title {
    font-weight: bold;
    font-size: $font-size-caption;
    margin-bottom: $spacer-sm;
    small {
      display: block;
      font-size: $font-size-sub-heading;
      font-weight: 200;
      line-height: 1;
      font-family: $font-family-curve;
      color: $primary;
    }
  }
  &__subtitle {
    display: none;
    font-family: $font-family-serif;
    margin-bottom: $spacer-sm;
  }
  &__description {
    font-size: $font-size-small;
  }
  @include up(lg) {
    text-align: center;
    margin-bottom: 0;
    &__eyecatch {
      display: none;
    }
    &__content {
      padding: $spacer;
    }
    &__title {
      font-size: $font-size-body;
    }
    &__subtitle {
      display: block;
    }
  }
}
