.l-confirm {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: $spacer;
  grid-template-areas:
    'cart'
    'invoice'
    'detail'
    'options';
  &__cart {
    grid-area: cart;
  }
  &__invoice {
    grid-area: invoice;
    @include down(md) {
      .p-invoice {
        background: none;
        padding: 0;
        margin-bottom: 0 !important;
        h2 {
          display: none;
        }
        &.--my-orders {
          background: $orange-1 !important;
          padding: $spacer-lg !important;
          margin-bottom: $spacer !important;
          h2,h3,h4 {
            display: block !important;
          }
        }
      }
    }
  }
  &__detail {
    grid-area: detail;
  }
  @include up(lg) {
    padding-bottom: $spacer-lg !important;
    grid-template-columns: minmax(0, 1fr) 320px;
    grid-gap: $spacer-lg;
    grid-template-areas:
      'cart   invoice'
      'options   invoice'
      'detail invoice';
  }
  &-action {
    margin-bottom: $spacer-lg;
    width: 100%;
    text-align: center;
    @include up(lg) {
      display: none;
    }
    p {
      margin-bottom: $spacer;
    }
    &.is-active {
      @include down(md) {
        position: fixed;
        bottom: 0;
        left: 0;
        margin-bottom: 0;
        background: $white;
        padding: $spacer;
        border-top: 1px solid $border-light-color;
        box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.03);
        animation: bottomToTop 0.5s;
        @keyframes bottomToTop {
          0% {
            transform: translateY(10rem);
          }
          100% {
            transform: translateY(0);
          }
        }
      }
    }
  }
}
