.p-author {
  display: flex;
  padding: $spacer-lg;
  margin-bottom: $spacer;
  background: $gray-1;
  &__thumb {
    width: 30rem;
    margin-right: $spacer-lg;
  }
  &__content {

  }
  &__name {
    font-size: 1.7rem;
    font-weight: bold;
  }
  &__title {
    color: $gray-9;
    font-size: 1.2rem;
    font-weight: bold;
  }
  &__profile {
    margin-top: $spacer-sm;
    @include down(sm) {
      font-size: $font-size-caption;
    }
  }
}
