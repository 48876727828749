.p-inquiry {
  display: flex;
  justify-content: space-between;
  &__description {
    margin-right: $spacer;
    h2, h3 {
      font-weight: $font-weight-bold;
      font-size: $font-size-sub-heading;
    }
    p {
      font-size: $font-size-caption;
    }
  }
  &__actions {
    display: flex;
    align-items: center;
  }
  &__tel {
    font-size: $font-size-small;
    a {
      font-family: $font-family-serif;
      font-style: italic;
      font-weight: $font-weight-normal;
      font-size: 2.85rem;
      line-height: 1;
      letter-spacing: -.1rem;
      display: block;
    }
    small {
      font-size: $font-size-caption;
      &::before {
        content: '営業時間:';
        margin-right: $spacer-sm/2;
      }
    }
  }
  &__line{
    margin-left: $spacer;
    @include up(md) {
      margin-left: $spacer-sm;
    }
  }
  @include down(md) {
    padding: 0 $spacer;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .p-inquiry__description { margin-right: 0; }
    .p-inquiry__actions { margin-top: $spacer-sm; }
  }
  @include down(sm) {
    p { display: none; }
    h2, h3 { font-size: $font-size-body; }
    .p-inquiry__tel {
      a {
        font-size: 2.8rem;
        display: block;
        letter-spacing: .05rem;
      }
    }
  }
}
