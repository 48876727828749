.l-post {
  .c-container {
    @include up(md) {
      width: map-get($breakpoints-from, md);
      margin: 0 auto;
      .l-post__aside {
        margin-bottom: $spacer-lg;
      }
    }
    @include up(lg) {
      display: flex;
      width: auto;
      .l-post__main {
        width: map-get($breakpoints-from, lg);
        flex-shrink: 0;
      }
      .l-post__aside {
        margin-left: $spacer-lg * 1.5;
      }
    }
  }
  &__header {
    margin-bottom: $spacer-lg;
    img {
      border-radius: $border-radius;
    }
    h1 {
      font-size: $font-size-hero*1.3;
      margin-top: $spacer;
      margin-bottom: $spacer-sm;
    }
    time {
      font-size: $font-size-caption;
      color: $muted;
      &[itemprop='datePublished'] {
        margin-right: $spacer;
        &::before {
          content: '公開日:';
          margin-right: $spacer-sm/2;
        }
      }
      &[itemprop='dateModified'] {
        &::before {
          content: '更新日:';
          margin-right: $spacer-sm/2;
        }
      }
    }
  }
  &__article {
    @extend .p-note;
    margin-bottom: $spacer-lg;
  }
  &__main {
    margin-bottom: $spacer-lg;
  }
  &__aside {
    width: 100%;
    margin-bottom: $spacer-sm;
  }
  &__eyecatch {
    text-align: center;
    margin-bottom: $spacer-lg;
    margin-top: $spacer;
    @include down(sm) {
      margin-top: 0;
      margin-right: calc(50% - 50vw);
      margin-left: calc(50% - 50vw);
      img {
        border-radius: 0;
      }
    }
  }
  &__time {
    margin-bottom: $spacer;
  }
  &__share {
    display: flex;
    align-items: start;
    list-style-type: none;
    padding-left: 0;
    li {
      position: relative;
      &.--facebook {
        top: -1px;
      }
      + li {
        margin-left: $spacer-sm;
      }
    }
  }
  &.--archive {
    .c-container {
      @include up(md) {
        width: 64rem;
      }
      @include up(lg) {
        width: auto;
      }
    }
    .l-post__main {
      @include up(lg) {
        width: 64rem;
        flex-shrink: 0;
      }
    }
  }
  &.--single-col {
    .c-container {
      justify-content: center;
      @include up(lg) {
        width: 64rem;
        .l-post__main {
          width: 100%;
        }
        .l-post__aside {
          width: 100%;
          margin-left: 0;
          margin-bottom: $spacer-lg;
        }
      }

    }
  }
}
