// Base
%label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: $border-width solid $gray-7;
  background: $gray-7;
  color: $white;
  font-size: $label-font-size;
  text-decoration: none;
  line-height: 1;
  font-weight: 400;
  padding: $label-padding-y $label-padding-x;
  border-radius: $border-rounded;
  vertical-align: text-bottom;
}

// Style
.c-label {
  @extend %label;
  &.--outline {
    background: $white;
    border-color: $gray-7;
    color: $gray-7;
  }
  &.--category {
    &-1 {
      background: $white;
      border-color: $cyan-7;
      color: $cyan-7;
    }
    &-2 {
      background: $white;
      border-color: $red-7;
      color: $red-7;
    }
    &-3 {
      background: $white;
      border-color: $teal-7;
      color: $teal-7;
    }
    &-4 {
      background: $white;
      border-color: $orange-7;
      color: $orange-7;
    }
    &-option {
      background: $white;
      border-color: $gray-7;
      color: $gray-7;
    }
  }
}

// Themes
@each $key, $value in $button-colors {
  .c-label {
    &.--#{$key} {
      background: $value;
      border-color: $value;
      color: $white;
    }
    &.--outline-#{$key} {
      background: $white;
      border-color: $value;
      color: $value;
    }
    &.--transparent-#{$key} {
      background: none;
      border-color: $value;
      color: $value;
    }
  }
}
