.p-point-number {
  display: inline-block;
  padding-bottom: $spacer/4;
  // border-bottom: 1px dotted $primary;
  font-family: $font-family-serif;
  font-style: italic;
  font-size: $font-size-heading;
  color: $primary;
  font-weight: 200;
  line-height: 1;
  margin-bottom: $spacer/2;
  strong {
    margin-left: $spacer/3;
    font-size: $font-size-hero;
    font-weight: 200;
  }
}
.p-point {
  text-align: center;
  margin-bottom: $spacer*2;
  &__number {
    @extend .p-point-number;
    padding-bottom: 0;
  }
  &__title {
    font-size: $font-size-body;
    font-weight: $font-weight-normal;
    margin-bottom: $spacer/4;
  }
}
.p-point-lg {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr auto;
  grid-gap: $spacer-sm;
  grid-template-areas:
    "title eyecatch"
    "content content";
  padding: $spacer;
  background: $white;
  border-radius: $border-radius;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, .05);
  margin-bottom: $spacer-lg;
  &__title {
    grid-area: title;
    h1,h2,h3 {
      color: $primary;
    }
  }
  &__eyecatch {
    grid-area: eyecatch;
    margin-bottom: $spacer-sm;
    min-width: 8rem;
  }
  &__content {
    grid-area: content;
    * + * { margin-top: $spacer-sm; }
  }
  @include up(lg) {
    grid-template-areas:
      "title title"
      "content eyecatch";
    padding: $spacer-lg;
    &__title {
      display: flex;
      .p-point-number {
        margin-right: $spacer-sm;
      }
      h1,h2,h3 {
        margin-top: -0.2rem;
      }
    }
    &__eyecatch {
      margin-left: $spacer-lg;
      margin-bottom: 0;
      img { min-width: 15rem; }
    }
  }
}
