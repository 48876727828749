// Layout
.c-form {
  display: flex;
  align-items: flex-start;
  margin-bottom: $spacer-lg;
  @include down(md) {
    flex-direction: column;
    margin-bottom: $spacer * 1.5;
  }
  &.--vertical {
    flex-direction: column;
    .c-form__label {
      text-align: left;
    }
  }
}

// Label
.c-form {
  %form-label {
    position: relative;
    bottom: 0.1rem;
    margin-left: $spacer/2;
    @include down(md) {
      bottom: 0;
    }
  }
  &__label {
    min-width: 16rem;
    margin-right: $spacer;
    padding: 0.95rem 0;
    text-align: right;
    font-size: $font-size-body;
    font-weight: 600;
    @include down(md) {
      padding: 0 0 0.45rem;
      font-size: $font-size-caption;
      text-align: left;
    }
    &.--required:after {
      @extend %label;
      @extend %form-label;
      background-color: $danger !important;
      border-color: $danger !important;
      content: '必須';
      @include down(md) {
        bottom: -0.15rem !important;
      }
    }
    &.--optional:after {
      @extend %label;
      @extend %form-label;
      content: '任意';
      @include down(md) {
        bottom: -0.15rem !important;
      }
    }
  }
}

// Form Content
.c-form {
  &__control {
    width: 100%;
    .c-form.--sub {
      @extend .u-mt-1;
    }
  }
  &__block {
    margin-top: $spacer;
  }
  &__help {
    margin-top: $spacer/2;
    color: $gray-8;
    font-size: $font-size-caption;
  }
  &__count {
    margin-top: $spacer/2;
    font-size: $font-size-caption;
    text-align: right;
  }
  &.--center {
    justify-content: center;
    @include down(md) {
      justify-content: flex-start;
      align-items: center;
    }
  }
  &__action {
    display: flex;
    width: 100%;
    justify-content: center;
    @include down(lg) {
      .c-button {
        margin-right: $spacer-sm;
        margin-left: $spacer-sm;
      }
    }
    @include down(md) {
      flex-direction: row;
      justify-content: space-between;
      .c-button {
        margin-right: 0;
        margin-left: 0;
      }
      .c-button.--link {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .c-form__warning {
    margin-top: $spacer/2;
    font-size: $font-size-caption;
    color: $warning;
  }
}

// Error
.c-form.is-error {
  .c-input,
  .c-textarea {
    border-color: $form-error-border;
    background: $form-error-bg;
    color: $form-error;
    @include placeholder {
      color: $form-error-placeholder;
    }
  }
  .c-select {
    border-color: $form-error-border;
    background: $form-error-bg;
    background-image: $select-icon-error;
    color: $form-error;
  }
  .c-radio,
  .c-checkbox {
    label {
      color: $form-error;
      &::before {
        background: $form-error-bg !important;
        border-color: $form-error-border !important;
      }
    }
  }
  .c-file {
    color: $form-error;
    .c-button {
      background: $pink-6;
      border-color: $pink-6;
    }
  }
  .c-form__error {
    margin-top: $spacer/2;
    color: $pink-8;
    font-size: $font-size-caption;
  }

  &.--wrap {
    flex-wrap: wrap;
    .c-form__error {
      flex-basis: 100%;
      text-align: center;
    }
  }
}

// Input Group
.c-input-group {
  display: flex;
  align-items: center;
  > * {
    min-width: 0;
    + * {
      margin-left: $spacer;
    }
  }
  .c-form__error {
    width: 100%;
  }
}

// Text
.c-input {
  @include input;
  @include placeholder {
    color: $placeholder-color;
  }
  &[type='date'] {
    // iOS Safari用不具合
    min-width: 15rem;
    min-height: $input-padding-y * 2 + $font-size-body * 1.4;
  }
  &.disabled {
    pointer-events: none;
    cursor: no-drop;
  }
}

// File
.c-file {
  display: flex;
  align-items: center;
  margin: 0.65rem 0;
  input[type='file'] {
    display: none;
  }
  small {
    padding: 0 $spacer/2;
    font-size: $font-size-body;
  }
  .c-button {
    @extend .--sm;
  }
}

// Select
.c-select {
  @include input;
  padding-right: 2.8rem;
  background: $input-bg-color;
  background-image: $select-icon;
  background-position: right 1rem center !important;
  background-repeat: no-repeat !important;
  option[disabled] {
    display: none;
  }
}

// Checkbox
.c-checkbox {
  @include checkbox;
  input[type='checkbox'] {
    &:not(:checked),
    &:checked {
      + label {
        &::before {
          border-radius: $border-radius-sm;
        }
        &::after {
          content: $checkbox-icon;
          width: $checkbox-size;
          height: $checkbox-size;
          line-height: $checkbox-size;
          text-align: center;
          left: 0;
        }
      }
    }
  }
}

.c-form .c-checkbox {
  padding: $input-padding-y 0;
}

// Radio
.c-radio {
  @include checkbox;
  input[type='radio'] {
    &:not(:checked),
    &:checked {
      + label {
        &::before {
          border-radius: $border-rounded;
        }
        &::after {
          width: $checkbox-size;
          height: $checkbox-size;
          line-height: $checkbox-size;
          text-align: center;
          left: 0;
          content: $radio-icon;
        }
      }
    }
  }
}
.c-form .c-radio {
  padding: $input-padding-y 0;
}

// Textarea
.c-textarea {
  @include input;
  min-height: $spacer * 8;
  margin-bottom: -0.5rem;
  @for $i from 1 through 20 {
    &.--row-#{$i} {
      min-height: $spacer * $i;
    }
  }
}

// Legend
.c-legend {
  @extend %heading-sm;
  margin-bottom: $spacer-lg;
  &__link {
    @extend %heading-sm-link;
  }
}
.c-container .c-legend {
  @extend %heading-sm-container;
}
